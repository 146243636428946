import { getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard"
import { createBookings, getUserAllBookings, updateBookings } from "Adapters/APIs/Concierge/Bookings"
import { getAllCategories, getAllCuisine, getAllRestaurents } from "Adapters/APIs/restaurants"
import { objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export const initialBookingForm = {
    restaurant_id: '',
    guest_name: '',
    total_pax: '',
    date: '',
    time: '',
    occasion: '',
    allergies: '',
    special_note: '',
    guest_phone_number: '',
}

const useCreateBookings = (close, selectRestaurant, data, onSuccess) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage, removeDataFromSessionStorage, storeDataInSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const userLocation = getDataFromLocalStorage('userLocation')

    const { errors, setErrors, validation, validateNumber } = useValidations()

    // redux states
    const state = useSelector(state => state)
    const restaurants = state?.adminDashboard?.dropdown_restaurants
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories

    // states
    const [loading, setLoading] = useState(false)
    const [bookingForm, setBookingForm] = useState(initialBookingForm)
    const [loader, setLoader] = useState(true)
    const [filterPopup, setFilterPopup] = useState(false)
    const [filterData, setFilterData] = useState()
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)
    const [appliedFilter, setAppliedFilter] = useState()


    // on booking change handler
    const onBookingChangeHandler = (e) => {
        const { name, value } = e?.target
        if (name == 'date') {
            setBookingForm({
                ...bookingForm,
                [name]: value,
                time: e?.target?.time
            })
        }
        if (name == 'guest_phone_number') {
            setErrors((prev) => ({ ...prev, [name]: '', }));
            validateNumber(name, value);
            setBookingForm({
                ...bookingForm,
                guest_phone_number: value,
                guest_phone_code: value?.split('-')[0]?.replace('+', '')
            })
            return
        }
        if (name !== 'date') {
            setBookingForm({
                ...bookingForm,
                [name]: value
            })
        }
        setErrors({
            ...errors,
            [name]: ''
        })
    }

    // on FIlter Change
    const onChangeHandler = (e) => {
        const { name, value } = e?.target
        setFilterData({
            ...filterData,
            [name]: value
        })
    }

    // get dashboard cards data
    const getRestaurants = (type, clear) => {
        let payload = {
            category: !clear ? filterData?.category : '',
            cuisine_id: !clear ? filterData?.cuisine_id : '',
            price_per_cover: !clear ? filterData?.price_per_cover : '',
            user_location: !clear ? filterData?.radius && userLocation : '',
            radius: !clear ? filterData?.radius : '',
            google_rating: !clear ? filterData?.google_rating : '',
            tripadvisor_rating: !clear ? filterData?.tripadvisor_rating : '',
            sort_by: !clear ? filterData?.sort_by : '',
        }
        delete payload.radius
        if (filterData?.sort_by == 'name') {
            payload = {
                ...payload,
                sort_order: "asc"
            }
        }
        if (filterData?.sort_by == 'distance') {
            payload = {
                ...payload,
                radius: !clear ? filterData?.radius : '',
            }
            delete payload.sort_by
        }

        setFilterPopup(false)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        if (type != 1 || filterData?.radius || filterData?.sort_by || filterData?.sort_order || filterData?.category || filterData?.cuisine_id || filterData?.price_per_cover
            || filterData?.google_rating || filterData?.tripadvisor_rating) {
            setLoader(true)
            if (clear) {
                setAppliedFilter()
            } else
                setAppliedFilter({
                    radius: payload?.radius,
                    sort_by: payload?.sort_by,
                    sort_order: payload?.sort_order,
                    category: payload?.category,
                    cuisine_id: payload?.cuisine_id,
                    price_per_cover: payload?.price_per_cover,
                    google_rating: payload?.google_rating,
                    tripadvisor_ratin: payload?.tripadvisor_rating,
                })
            dispatch(getDropdownRestaurantAPI('', payload, success, fail))
        } else {
            setFilterPopup(false)
        }
    }

    // on Booking Creation
    const onCreateBooking = () => {
        const { restaurant_id, guest_name, total_pax, date, time, guest_phone_number } = bookingForm
        let err = validation({ restaurant_id, guest_name, total_pax, date, time })

        if (guest_phone_number?.split('-')[1]) {
            validateNumber('guest_phone_number', guest_phone_number);
        }

        const validationError = errors?.guest_phone_number && guest_phone_number?.split('-')[1]
            ? {
                ...err,
                guest_phone_number: errors?.guest_phone_number,
            }
            : err
        setErrors(validationError)

        if (objectKeyConvertToArray(validationError)?.length == 0) {
            setLoading(true)
            let payload = { ...bookingForm, total_pax: +bookingForm?.total_pax }
            if (payload?.guest_phone_number) {
                payload = {
                    ...payload,
                    guest_phone_number: bookingForm?.guest_phone_number?.split('-')[1]
                }
            }
            const success = (data) => {
                close && close()
                onSuccess && onSuccess()
                setLoading(false)
                setBookingForm(initialBookingForm)
                if (getDataFromLocalStorage('defaultRoute') == '/concierge/dashboard' && !window.location.pathname.includes(`/concierge/booking`)) {
                    navigate(`/concierge/booking/?reservation=${data?.number}`)
                }
                removeDataFromSessionStorage('filterData')
            }
            const fail = () => {
                setLoading(false)
            }
            if (data?.id)
                dispatch(updateBookings(access_token, {
                    ...payload,
                    time: moment(payload?.time, 'HH:mm:ss').format('HH:mm')
                }, success, fail))
            else
                dispatch(createBookings(access_token, payload, success, fail))
        }
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    const Cuisines = useMemo(() => {
        return cuisinse?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [cuisinse])

    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [categoriesData])

    useEffect(() => {
        getRestaurants()
        dispatch(getAllCuisine())
        getCategories()
        storeDataInSessionStorage('create_booking', true)
    }, [])


    useEffect(() => {
        if (selectRestaurant) {
            setBookingForm({
                ...bookingForm,
                restaurant_id: selectRestaurant
            })
        }
        if (data?.id) {
            setBookingForm({
                ...bookingForm,
                restaurant_id: data?.restaurant_id,
                guest_name: data?.guest_name,
                total_pax: data?.total_pax,
                number: data?.number,
                date: data?.date,
                time: data?.time,
                occasion: data?.occasion,
                allergies: data?.allergies,
                guest_phone_number: data?.guest_phone_code && "+" + data?.guest_phone_code + '-' + data?.guest_phone_number,
                special_note: data?.special_note,
            })
        }
    }, [selectRestaurant, data])

    return {
        errors, onCreateBooking, restaurants, onBookingChangeHandler, bookingForm, setErrors, setBookingForm, loading, onChangeHandler,
        filterPopup, setFilterPopup, filterData, setFilterData, getRestaurants, Cuisines, loader, categories, appliedFilter,
        removeDataFromSessionStorage
    }

}

export default useCreateBookings