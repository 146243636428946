import React from 'react';
import Svgs from 'Assets/svgs';
import VerticalStatusSlider from 'Components/Elements/VerticalStatusSlider';
import StatusSlider from 'Components/Elements/StatusSlider';
import { currency } from 'Adapters/variables';

const PaymentCard = ({ date, setShowProof, amount, isSubscription, image, onClick, status, number }) => {
    return (
        <>
            <div className="bg-darkGrey rounded-md shadow-md p-4"
                onClick={() => { onClick && onClick() }}
            >
                <div className="flex flex-col xs:flex-row justify-between gap-2 items-center text-blueGrey">
                    <div className="flex w-full flex-col gap-3">
                        <span className=" font-semibold text-base">{date}</span>
                        <div className="flex items-center gap-3 flex-1">
                            <div className='flex gap-2 text-primary font-semibold px-3 py-1.5 bg-secondary text-sm rounded-md'>
                                {amount} {currency}
                            </div>
                            <button className='flex p-1.5 cursor-pointer  bg-lightGrey rounded-full'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowProof(image)
                                }}
                            >
                                <Svgs.AttachmentIcon fill={'var(--secondary-color)'} />
                            </button>
                            <button className='flex p-1.5 cursor-pointer  bg-lightGrey rounded-full'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (number) {
                                        isSubscription
                                            ? window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/membership?number=${number}`, '_blank')
                                            : window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/payment?number=${number}`, '_blank')
                                    }
                                }}
                            >
                                <Svgs.CashIcon width={'20'} height={'20'} fill={'var(--secondary-color)'} />
                            </button>
                        </div>
                    </div>
                    <div className="hidden xs:flex w-full max-w-32 ">
                        <VerticalStatusSlider
                            activeStep={status}
                            parentClass={"pr-8"}
                            steps={[
                                { name: 'Paid', value: 0 },
                                { name: 'Received by Admin', value: 1, unActiveIcon: <Svgs.RecievedPaymentIcon /> },
                            ]}
                        />
                    </div>
                    <div className="xs:hidden w-full">
                        <StatusSlider
                            activeStep={status}
                            parentClass={"pr-8"}
                            steps={[
                                { name: 'Paid', value: 0 },
                                { name: 'Received by Admin', value: 1 },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentCard;