import React from 'react'
import RestaurantForm from './RestaurantForm'
import ConciergeForm from './ConciergeForm'
import useRegister from './helper'
import OTPScreen from 'OnBaording/OTPVerification/OTPScreen'
import Svgs from 'Assets/svgs'

const Signup = () => {
    const {
        navigate, handleSubmit, step, showOTPScreen, setShowOTPScreen, handleChange, formData, errors, setErrors, selectedRole, cuisines, loading,
        addCuisine, setAddCuisine, getCuisineLoader, getCuisineData, categories, addBusinessName, setAddBusinessName, getBusinessNameLoader,
        businessData, setStep, businesses, getCategoriesLoader, roleValue,isSubCategoryRequired
    } = useRegister()

    return (
        <>
            <section className="flex h-[100vh] w-full">
                {showOTPScreen ? (
                    <OTPScreen
                        email={formData?.email}
                        show={showOTPScreen}
                        setShow={setShowOTPScreen}
                    />
                ) : (
                    <>
                        <div className='flex w-full'>
                            <div className="w-full flex flex-col space-y-8 flex-1 overflow-auto">
                                <div className="flex justify-center mt-2">
                                    <Svgs.AuthLogo width={'150'} height={'100'} />
                                </div>
                                <div className='px-4 pt-0 !m-0'>
                                    <div className='p-2'>
                                        <div className="flex flex-col items-center justify-center w-full">
                                            {selectedRole === 'restaurant' && (
                                                <RestaurantForm
                                                    handleChange={handleChange}
                                                    formData={formData}
                                                    cuisines={cuisines}
                                                    errors={errors}
                                                    setErrors={setErrors}
                                                    handleSubmit={handleSubmit}
                                                    loading={loading}
                                                    addCuisine={addCuisine}
                                                    setAddCuisine={setAddCuisine}
                                                    getCuisineLoader={getCuisineLoader}
                                                    getCuisineData={getCuisineData}
                                                    categories={categories}
                                                    getCategoriesLoader={getCategoriesLoader}
                                                    navigate={navigate}
                                                    isSubCategoryRequired={isSubCategoryRequired}
                                                    roleValue={roleValue}
                                                />
                                            )}
                                            {selectedRole === 'concierge' && (
                                                <ConciergeForm
                                                    handleChange={handleChange}
                                                    handleSubmit={handleSubmit}
                                                    formData={formData}
                                                    step={step}
                                                    setStep={setStep}
                                                    setErrors={setErrors}
                                                    errors={errors}
                                                    loading={loading}
                                                    businessData={businessData?.length > 0 ? businessData : businesses}
                                                    addBusinessName={addBusinessName}
                                                    setAddBusinessName={setAddBusinessName}
                                                    getBusinessNameLoader={getBusinessNameLoader}
                                                />
                                            )}
                                        </div>
                                        <div className="flex items-center justify-center gap-2 text-sm">
                                            <p className='text-white'>Already have an account?</p>
                                            <span className='text-secondary cursor-pointer' onClick={() => navigate('/login')}>Sign In</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex-1 w-full h-[100%] bg-cover bg-no-repeat bg-center md:block hidden"
                                style={{ backgroundImage: "url('/img/auth/signupdetails.png')" }}
                            >
                                <img className='h-full w-full' src="/img/auth/loginlayer.png" alt="" />
                            </div>
                        </div>
                    </>
                )}
            </section>
        </>
    )
}

export default Signup