import { getBusinessTypesData, postTradeLicense } from "Adapters/APIs/Authentication";
import { createNewBusinessAccount, OtpVerificationBusiness, resendOTPBusiness } from "Adapters/APIs/Restaurant/Business";
import { getAllCategories, getAllCuisine } from "Adapters/APIs/restaurants";
import { apiErrorMessage } from "Constants/Data/Errors";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify";

const initialState = {
    restaurant_name: '',
    categories: [],
    cuisine_id: '',
    contact_person: '',
    authorized_manager: '',
    email: '',
    phone_code: '',
    phone_number: '',
    mobile_code: '',
    mobile_number: '',
    address: '',
    trade_license: "",
    terms_condition: false,
    privacy_policy: false,
};

const useCreateBusiness = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { storeDataInSessionStorage, getDataFromSessionStorage, removeDataFromSessionStorage, getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const { errors, setErrors, validation, validateNumber, emailValidation } = useValidations()

    // states
    const [formData, setFormData] = useState(initialState);
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [addCuisine, setAddCuisine] = useState(false)
    const [getCuisineLoader, setGetCuisineLoader] = useState(true)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)
    const [emailSubmitLoader, setEmailSubmitLoader] = useState(false)
    const [existingCuisine, setExistingCuisine] = useState()
    const [roleValue, setRoleValue] = useState()
    const [businessTypes, setBusinessTypes] = useState([])
    const [loader, setLoader] = useState(true)

    // redux states
    const state = useSelector(state => state)
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories

    // setting selectedRole data
    const selectedRole = useMemo(() => {
        let business_types = businessTypes?.length > 0 ? businessTypes : [
            {
                "id": 1,
                "name": "Business",
                "slug": "business",
                "type": "restaurant",
                "status": 1
            },
            {
                "id": 2,
                "name": "Concierge",
                "slug": "concierge",
                "type": "concierge",
                "status": 1
            }
        ]
        setRoleValue(business_types?.find(itm => itm?.type == "restaurant"))
        return business_types?.find(itm => itm?.type == "restaurant")?.type?.toLowerCase()
    }, [businessTypes])

    // setting cuisines array
    const cuisines = useMemo(() => {
        let allData = cuisinse?.map(itm => ({
            label: itm?.name,
            value: itm?.id
        })) || []; // Default to an empty array if cuisinse is undefined

        if (existingCuisine && existingCuisine?.value) {
            allData = [
                ...allData,
                {
                    label: existingCuisine.label,
                    value: existingCuisine.value
                }
            ];
        }

        if (formData?.categories?.length == 0) {
            allData = [];
        }

        // Remove duplicates based on the 'value' property
        const uniqueData = allData?.filter(
            (item, index, self) =>
                index === self?.findIndex((i) => i?.value === item?.value)
        );

        return uniqueData;
    }, [cuisinse, existingCuisine, formData?.categories]);

    // setting categories array
    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id,
                icon: itm?.icon,
                is_sub_category_required: itm?.is_sub_category_required
            }
        })
    }, [categoriesData])

    useEffect(() => {
        getCategories()
        getBusinessTypes()
    }, [])

    useEffect(() => {
        let businessData = getDataFromSessionStorage("businessData");
        if (businessData) {
            setFormData(businessData)
            setStep(2)
        } else {
            setFormData(initialState)
            setErrors()
            setStep(1)
        }
    }, [])

    // getting cuisines data on behalf of selected categories
    useEffect(() => {
        if (formData?.categories?.length > 0) {
            getCuisineData()
        } else {
            setGetCuisineLoader(false)
            setFormData((prev) => ({
                ...prev,
                cuisine_id: ''
            }))
            setExistingCuisine()
        }
    }, [formData?.categories, formData?.categories?.length])

    // settting cuisine data
    useEffect(() => {
        if ((cuisines?.length > 0 && formData?.cuisine_id && !cuisines?.find(itm => itm?.value == formData?.cuisine_id)) || cuisines?.length == 0) {
            setFormData((prev) => ({
                ...prev,
                cuisine_id: ''
            }))
        }
    }, [cuisines, formData?.cuisine_id])

    useEffect(() => {
        if (existingCuisine && existingCuisine?.value && formData?.cuisine_id == '') {
            setFormData((prev) => ({
                ...prev,
                cuisine_id: existingCuisine?.value
            }))
        }
    }, [existingCuisine, formData?.cuisine_id])

    // setting isSubCategoryRequired check
    const isSubCategoryRequired = useMemo(() => {
        const selectedIds = formData?.categories || [];
        let filteredData = categories?.filter(category => selectedIds?.includes(category?.value));
        if (filteredData?.find(itm => itm?.is_sub_category_required == 1) && categories?.length > 0) {
            return true
        } else {
            return false
        }
    }, [categories, formData?.categories]);

    // get business types
    const getBusinessTypes = () => {
        const success = (data) => {
            setLoader(false)
            if (data?.data?.business_types?.length > 0) {
                setBusinessTypes(data?.data?.business_types?.slice(0, 2))
            }
        }
        const fail = () => {
            setLoader(false)
        }
        setLoader(true)
        dispatch(getBusinessTypesData(success, fail))
    }

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'phone_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                mobile_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                phone_number: value,
                phone_code: value?.split('-')[0]?.replace('+', '')
            })
        }
        if (name == 'mobile_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                phone_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                mobile_number: value,
                mobile_code: value?.split('-')[0]?.replace('+', '')
            })
        }

        if (name == 'email') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            emailValidation(name, value);
            setFormData({
                ...formData,
                [name]: value
            })
        }
        if (name == 'trade_license') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.files[0]
            })
        }
        if (name == 'privacy_policy' || name == 'terms_condition') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        if (name != 'phone_number' && name != 'mobile_number' && name != 'email' && name != 'privacy_policy' && name != 'terms_condition'
            && name != 'trade_license') {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    // handle submit form
    const handleSubmit = () => {
        let requiredFields = {}
        const { email, cuisine_id, phone_number, mobile_number, restaurant_name, address, country, categories,
            state, city, contact_person, terms_condition, privacy_policy, trade_license
        } = formData

        let payload = { ...formData }
        requiredFields = {
            restaurant_name,
            categories: categories?.length == 0 ? false : true,
            email,
            phone_number: phone_number ? phone_number : mobile_number ? true : false,
            phone_code: phone_number ? phone_number : mobile_number ? true : false,
            mobile_code: mobile_number ? mobile_number : phone_number ? true : false,
            mobile_number: mobile_number ? mobile_number : phone_number ? true : false,
            address, state, country, city, trade_license,
            terms_condition, privacy_policy, contact_person
        }
        if (isSubCategoryRequired) {
            requiredFields = {
                ...requiredFields,
                cuisine_id: cuisine_id
            }
        }
        setErrors((prev) => ({
            ...prev,
            ...validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, })
        }));
        let result = validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, });
        if (objectKeyConvertToArray(result)?.length === 0 && !errors?.password_confirmation) {
            const success = (data) => {
                storeDataInSessionStorage('businessData', { ...formData, user_id: data?.id })
                setStep(2)
                // navigate('/otp-verification')
                setLoading(false)
            }
            const fail = (error) => {
                setLoading(false)
                if (error?.response?.data?.status == 422) {
                    if (error?.response?.data?.error?.email || error?.response?.data?.error?.mobile_number) {
                        setErrors((prev) => ({
                            ...prev,
                            'email': error?.response?.data?.error?.email ? error?.response?.data?.error?.email[0] : false,
                            'mobile_number': error?.response?.data?.error?.mobile_number ? error?.response?.data?.error?.mobile_number[0] : false,
                            'phone_number': error?.response?.data?.error?.phone_number ? error?.response?.data?.error?.phone_number[0] : false
                        }));
                        setStep(1)
                    }
                }
            }
            if (payload?.phone_number) {
                payload = {
                    ...payload,
                    phone_number: formData?.phone_number?.split('-')[1]
                }
            }
            if (payload?.mobile_number) {
                payload = {
                    ...payload,
                    mobile_number: formData?.mobile_number?.split('-')[1]
                }
            }
            payload = {
                ...payload,
                first_name: contact_person
            }
            if (formData?.categories?.length > 0) {
                const formattedCategories = categories.reduce((acc, value, index) => {
                    acc[`categories[${index}]`] = value;
                    return acc;
                }, {});
                payload = {
                    ...payload,
                    ...formattedCategories
                }
            }
            delete payload.categories
            if (payload?.phone_number == payload?.mobile_number) {
                setErrors((prev) => ({
                    ...prev,
                    'mobile_number': "Mobile number and Phone number must be different"
                }));
                toast.error("Mobile number and Phone number must be different", { toastId: "toast" })
            } else {
                submitFuncntion(payload, success, fail)
            }
        }
    };

    // handle submit function
    const submitFuncntion = (payload, success, fail) => {
        removeDataFromSessionStorage('signupData')
        setLoading(true)
        dispatch(createNewBusinessAccount(access_token, payload, success, fail))
    }


    // resend email otp
    const resendEmailOTP = () => {
        let businessData = getDataFromSessionStorage("businessData");

        let payload = {
            type: 'email',
            email: formData?.email || businessData?.email
        };
        const success = (response) => {

        }
        const fail = (response) => { }
        dispatch(resendOTPBusiness(access_token, payload, success, fail));
    }

    // handle submit otp for verification
    const verifyOtp = async (otp) => {
        try {
            if (!formData?.email && (getDataFromSessionStorage("businessData") === null || getDataFromSessionStorage("businessData") === undefined)) {
                navigate("/restaurant/business");
            } else {
                let businessData = getDataFromSessionStorage("businessData");
                let { num1, num2, num3, num4 } = otp;
                if ((num1 && num2 && num3 && num4)) {
                    let verification_code = `${num1}${num2}${num3}${num4}`;
                    const payload = {
                        type: 'email',
                        email: businessData?.email || formData?.email,
                        otp: verification_code,
                    };
                    const fail = (err) => {
                        setEmailSubmitLoader(false);
                        if (err?.response?.data?.status == 403) {
                            navigate("/restaurant/business")
                        } else {
                            toast.error(apiErrorMessage, { toastId: 'toast' });
                        }
                    };
                    let success = (response) => {
                        setEmailSubmitLoader(false);
                        navigate("/restaurant/business")
                    };

                    setEmailSubmitLoader(true);
                    dispatch(OtpVerificationBusiness(access_token, payload, success, fail));
                }
            }
        } catch (error) {
            setEmailSubmitLoader(false)
        }
    };

    // get cuisine
    const getCuisineData = (existingId, existingName) => {
        const success = () => {
            if (existingId) {
                setFormData({
                    ...formData,
                    'cuisine_id': existingId
                })
                setErrors((prev) => ({
                    ...prev,
                    'cuisine_id': '',
                }));
                setExistingCuisine({
                    label: existingName,
                    value: existingId
                })
            }
            setAddCuisine(false)
            setGetCuisineLoader(false)
        }
        const fail = () => {
            setGetCuisineLoader(false)
        }
        let payload = {
            category_id: `[${formData?.categories}]`,
            cuisine_id: formData?.cuisine_id
        }
        setGetCuisineLoader(true)
        dispatch(getAllCuisine(payload, success, fail))
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    return {
        handleSubmit, handleChange, formData, errors, setErrors, cuisines, loading, addCuisine, setAddCuisine, getCuisineLoader,
        getCuisineData, categories, step, setStep, navigate, resendEmailOTP, verifyOtp, emailSubmitLoader, isSubCategoryRequired, roleValue

    }
}

export default useCreateBusiness