import { updateUnapprovedRestaurant } from 'Adapters/APIs/Admin/dashboard';
import { updateEmailRequest, updateUser } from 'Adapters/APIs/Authentication';
import { getAllBusiness } from 'Adapters/APIs/restaurants';
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useEditProfile = (setIsEdit, editData, successCallback) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { errors, setErrors, validation, emailValidation } = useValidations()

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const user = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')

    // redux states
    const state = useSelector(state => state)
    const businessData = state?.business?.businesses

    // states
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(false)
    const [updateEmail, setUpdateEmail] = useState(false)
    const [OTPLoading, setOTPLoading] = useState(false)
    const [addBusinessName, setAddBusinessName] = useState(false)
    const [getBusinessNameLoader, setBusinessNameLoader] = useState(true)


    // input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'profile_picture') {
            setFormData({
                ...formData,
                profile_picture: e.target.files[0],
            })
        } else if (name == 'agreement') {
            setFormData({
                ...formData,
                agreement: e.target.files[0],
            })
        } else if (name == 'wa_number') {
            setFormData({
                ...formData,
                wa_number: value,
            })
        } else if (name == 'email') {
            setFormData({
                ...formData,
                [name]: value
            })
            emailValidation(name, value);
        } else if (name == 'edit_access') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        else {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    // Submit profile
    const handleSubmit = () => {
        let requiredFields = {}
        const { first_name, last_name, business_name, mobile_number, dob, payment_method, iban_number, card_holder_name, bank_name, wa_number } = formData

        requiredFields = {
            first_name,
            last_name,
            mobile_number: mobile_number,
            business_name,
            dob,
            payment_method,
            wa_number: wa_number?.split("-")[1]?.length > 0 ? wa_number : mobile_number?.split("-")[1]?.length > 0 ? mobile_number : ""
        }
        if (payment_method == 'bank') {
            requiredFields = { ...requiredFields, iban_number, card_holder_name, bank_name, }
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            const success = (data) => {
                if (editData) {
                    if (successCallback) {
                        successCallback()
                    } else {
                        navigate(-1)
                    }
                } else {
                    storeDataInLocalStorage('user_data', data)
                }
                setLoading(false)
                setIsEdit && setIsEdit(false)
            }
            const fail = () => {
                setLoading(false)
            }

            let payload = {
                ...formData,
                agreement: formData?.agreement?.name ? formData?.agreement : '',
                mobile_code: formData?.mobile_number?.split('-')[0]?.replace('+', ''),
                mobile_number: formData?.mobile_number?.split('-')[1],
                wa_code: formData?.wa_number?.split('-')[0]?.replace('+', ''),
                wa_number: formData?.wa_number?.split('-')[1],
            }
            if (user?.profile_picture == formData?.profile_picture) {
                delete payload.profile_picture
            }
            delete payload.referral_code
            delete payload.rejection_thread
            setLoading(true)
            if (editData) {
                payload = {
                    ...payload,
                    user_id: editData?.id,
                }
                dispatch(updateUnapprovedRestaurant(access_token, payload, success, fail))
            } else {
                dispatch(updateUser(access_token, payload, success, fail))
            }
        } else {
            let element = document.querySelector(".topscroll")
            if(element){
                document.querySelector(".topscroll").scrollIntoView();
            }
        }
    };

    // Request Email OTP for updating email
    const requestOTP = (type, email, setStep) => {
        const sucess = () => {
            if (!email) {
                setStep && setStep(2)
            } else {
                setStep && setStep(4)
            }
            setOTPLoading(false)
        }
        const fail = () => { setOTPLoading(false) }

        if (type == 'new' && !email) {
            setErrors((prev) => ({ ...prev, 'newEmail': 'This field is required' }));
        }

        if (!errors?.newEmail) {
            setOTPLoading(true)
            dispatch(updateEmailRequest(access_token,
                {
                    type: type,
                    new_email: email ? email : '',
                },
                sucess, fail))
        }
    }

    // get business
    const getBusinessData = () => {
        const success = () => {
            setAddBusinessName(false)
            setBusinessNameLoader(false)
        }
        const fail = () => {
            setBusinessNameLoader(false)
        }
        setBusinessNameLoader(true)
        dispatch(getAllBusiness({ type: 'non_paginated' }, success, fail))
    }

    useEffect(() => {
        getBusinessData()
    }, [])

    useEffect(() => {
        if (editData) {
            setFormData({
                ...editData,
                type: 'concierge',
                wa_number: `${editData?.wa_code && editData?.wa_code != 'null' ? editData?.wa_code : ''}-${editData?.wa_number ? editData?.wa_number : ''}`,
                mobile_number: `${editData?.mobile_code}-${editData?.mobile_number ? editData?.mobile_number : ''}`
            })
        } else {
            setFormData({
                ...user,
                wa_number: `${user?.wa_code && user?.wa_code != 'null' ? user?.wa_code : ''}-${user?.wa_number ? user?.wa_number : ''}`,
                mobile_number: `${user?.mobile_code}-${user?.mobile_number ? user?.mobile_number : ''}`,
                bank_name: user?.payment_details?.bank_name,
                card_holder_name: user?.payment_details?.card_holder_name,
                iban_number: user?.payment_details?.iban_number,
            })
        }
    }, [editData])


    return {
        formData, navigate, errors, handleChange, loading, handleSubmit, updateEmail, setUpdateEmail, OTPLoading, setOTPLoading, requestOTP,
        emailValidation, user, addBusinessName, setAddBusinessName, getBusinessNameLoader, businessData
    }
}

export default useEditProfile