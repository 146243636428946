import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import useProfileBoosting from './helper'
import DashboardCard from 'Components/ConceirgeCards/DashboardCard'
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer'
import CheckBox from 'Components/Elements/Checkbox'
import { currency } from 'Adapters/variables'
import Button from 'Components/Elements/Button'
import ConfirmPayPopup from './Elements/ConfirmPayPopup'

const ProfileBoosting = () => {
    const { endDate, setEndDate, startDate, setStartDate, loader, settingData, slotsData, slotsLoader, selectedSlot, setSelectedSlot,
        slotsAvailabilityLoader, estimatedBill, proof, setProof, postAvailableSlotData, onPayClick, setOnPayClick, errors, setErrors,
        postSlotLoader
    } = useProfileBoosting()

    return (
        <DashboardLayout active={'profile-boosting'}>
            <main className="relative p-4 lg:ml-72 md:ml-64 pt-20 min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 w-full justify-between">
                    <div className="pt-4 space-y-5 w-full">
                        <div className="flex xs:flex-row flex-col justify-between xs:items-center">
                            <Back title={'Profile Boosting'} />
                        </div>
                    </div>
                </div>
                <div className='py-6'>
                    <div className='grid grid-cols-2 gap-4 items-center'>
                        {loader
                            ? Array.from({ length: 2 }).map(itm => (
                                <ConceirgeCardShimmer customPadding={"p-3"} showViewDetail={false} />
                            ))
                            :
                            <>
                                <DashboardCard
                                    headingClassName={'!flex-col-reverse'}
                                    title1={'Booking week day fee'}
                                    amount1={settingData?.profile_boosting_week_day_fee}
                                    showViewDetail={false}
                                    customPadding={"px-2 py-1"}
                                />
                                <DashboardCard
                                    headingClassName={'!flex-col-reverse'}
                                    title1={'Boosting weekend fee'}
                                    amount1={settingData?.profile_boosting_week_end_fee}
                                    showViewDetail={false}
                                    customPadding={"px-2 py-1"}
                                />
                            </>}
                    </div>

                    <div className='pt-6 flex flex-col space-y-6'>
                        <div className="flex justify-between xs:items-center">
                            <h2 className={`font-medium text-xl text-white`}>Select Date</h2>
                            <div className='flex justify-end gap-2'>
                                <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    className={'!w-full'}
                                    minDate={new Date()}
                                />
                            </div>
                        </div>
                        {slotsLoader ?
                            <div className='flex flex-col space-y-2'>
                                {Array.from({ length: 6 }).map(itm => (
                                    <div className='rounded-md h-32 bg-gray-500 animate-pulse'></div>
                                ))}
                            </div>
                            :
                            slotsData?.length > 0 ?
                                <div className='flex flex-col space-y-2'>
                                    <h2 className={`font-medium text-xl text-white`}>Top slot for boosting</h2>
                                    <div className={`grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2 grid-cols-1 gap-2 ${estimatedBill && "pb-4"}`}>
                                        {slotsData.map((itm, ind) => (
                                            <div className='rounded-md bg-darkGrey p-4'>
                                                <div className='flex justify-between items-center gap-2'>
                                                    <div className='flex items-center gap-2'>
                                                        <div className="relative w-7 h-7 bg-cover bg-center" style={{ backgroundImage: "url('/img/profile/Slotsbg.png')" }}>
                                                            <div className="absolute font-semibold inset-0 flex items-center justify-center text-primary">
                                                                {ind + 1}
                                                            </div>
                                                        </div>
                                                        <h2 className='text-white'>{itm?.name}</h2>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <h2 className='text-blueGrey text-sm'>Select this slot</h2>
                                                        <CheckBox
                                                            checked={selectedSlot == itm?.id}
                                                            onChange={() => {
                                                                if (selectedSlot == itm?.id) {
                                                                    setSelectedSlot()
                                                                } else {
                                                                    setSelectedSlot(itm?.id)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <hr className='my-3 border-lightGrey' />
                                                <div className='grid grid-cols-2 items-center gap-4'>
                                                    <div className='flex flex-col space-y-2'>
                                                        <p className='text-sm text-blueGrey'>Week day fees</p>
                                                        <h2 className='text-white'>{(+itm?.weekday_price)?.toFixed(2)} {currency}</h2>
                                                    </div>
                                                    <div className='flex flex-col space-y-2'>
                                                        <p className='text-sm text-blueGrey'>Weekend fees</p>
                                                        <h2 className='text-white'>{(+itm?.weekend_price)?.toFixed(2)} {currency}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
                {slotsAvailabilityLoader ?
                    <div className="fixed bottom-4 left-1/2 lg:ml-32 md:ml-24 transform -translate-x-1/2">
                        <div className='animate-pulse rounded-[10px] bg-gray-400 h-12 w-60'></div>
                    </div>
                    : estimatedBill &&
                    <div className="fixed bottom-4 left-1/2 lg:ml-32 md:ml-24 transform -translate-x-1/2">
                        <Button
                            onClick={() => {
                                setOnPayClick(!onPayClick)
                            }}
                            text={`Pay ${estimatedBill?.vat_added_amount} ${currency} (${estimatedBill?.total_amount} + ${estimatedBill?.vat} VAT)`}
                        />
                    </div>
                }
            </main>

            <ConfirmPayPopup
                open={onPayClick}
                close={() => {
                    setOnPayClick(false)
                    setProof()
                }}
                proof={proof}
                setProof={setProof}
                errors={errors}
                setErrors={setErrors}
                text={`Pay ${estimatedBill?.vat_added_amount} ${currency} (${estimatedBill?.total_amount} + ${estimatedBill?.vat} VAT)`}
                onSubmit={postAvailableSlotData}
                btnLoading={postSlotLoader}
            />
        </DashboardLayout>
    )
}

export default ProfileBoosting