import Svgs from 'Assets/svgs';
import React from 'react'

const PaymentCheckboxCard = ({
  title,
  customMenuStyle,
  icon,
  id,
  checked,
  onClick,
  error,
  padding,
  disabled,
  onChange,
  leftIcon,
  errorClass,
  borderColor
}) => {

  return (
    <>
      <div
        onClick={disabled ? () => { } : onClick}
        className={`${disabled && "opacity-[0.8] cursor-not-allowed"
          }  cursor-pointer border rounded-lg  ${error && " border-error"
          } ${checked ? " border border-secondary bg-darkGrey !text-[#ffff]" : `border ${borderColor ? borderColor : "border-darkGrey"} bg-darkGrey text-blueGrey`
          } pr-6 ${padding ? padding : ""
          } flex relative items-center gap-3 min-h-[2.8rem] h-[2.8rem]`}>
        <div className={`payment-checkbox h-full rounded-l-md ${checked ? error ? 'bg-error' : 'bg-secondary' : 'bg-secondary'}`}>
          <input
            type="checkbox"
            name={'payment_method'}
            value={id}
            id={`checkbox-${id}`}
            onChange={(e) => onChange && onChange(e)}
            checked={checked ? checked : false}
            disabled={disabled}
            autoComplete="off"
          />
          <label className='my-3' htmlFor={`checkbox-${id}`}></label>
        </div>
        <div className="flex items-center justify-between w-full gap-4">
          <div className="flex items-center gap-2 py-3">
            <div>{leftIcon}</div>
            <p className="font-semibold text-xs xs:text-sm">{title}</p>
          </div>
        </div>
      </div>
      {error &&
        <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] z-30 w-full justify-end ${errorClass}`}>
          <Svgs.I fill={'#eb3b3b'} /> {error}
        </p>
      }
    </>
  )
}

PaymentCheckboxCard.defaultProps = {
  onChange: () => { },
};

export default PaymentCheckboxCard