import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import ImageUploader from 'Components/Elements/ImageUploader'
import React from 'react'

const ProfileDetails = ({ user, setIsEditProfile, setOpen, setDeletePopup, deletePopup }) => {
    return (
        <>
            <div className="rounded-lg md:rounded-[10px] bg-darkGrey text-white p-5 md:p-8 flex sm:flex-row relative flex-col-reverse gap-5 justify-between items-start">
                <div className="flex flex-col sm:flex-row items-center gap-5 py-2 sm:gap-8">
                    <div className="relative h-fit w-fit min-w-20 min-h-20">
                        {user?.restaurant?.images?.length > 0 ?
                            <Image className="rounded-full border-2 border-white w-20 h-20 object-scale-down" customLoaderClass={"rounded-full border-2 border-white w-20 h-20 object-scale-down"} customLoaderHeight={'h-20'} src={user?.restaurant?.images[0]?.url} alt="user" />
                            : <Svgs.UserIcon2 width={'5rem'} height={'5rem'} />
                        }
                        {/* <img className="absolute bottom-0 right-0 h-6 w-5" src="/img/profile/star.svg" alt="star" /> */}
                    </div>
                    <div className='flex flex-col space-y-1 text-white pt-4'>
                        <h4 className="text-2xl break-words font-bold">{user?.restaurant?.name}</h4>
                        <p className="text-base break-words w-full break-all pb-2">{user?.restaurant?.email}</p>
                        <button
                            onClick={() => { setOpen(true) }}
                            className="w-fit px-5 py-1 bg-lightGrey rounded-full flex items-center gap-1 font-medium text-white"
                        >
                            <span><Svgs.StarIcon /></span>
                            Add Rating
                        </button>
                    </div>
                </div>
                {user?.is_parent &&
                    <div className="absolute top-2 right-4 " onClick={() => { setDeletePopup(!deletePopup) }}>
                        <label className="flex items-center sm:w-auto w-full justify-between gap-2 cursor-pointer">
                            <span>
                                <Svgs.DeleteIcon stroke={"var(--error-color)"} />
                            </span>
                            <p><span className="text-base font-semibold text-error">Delete Account</span></p>
                        </label>
                    </div>
                }
            </div>
            <div className="py-6">
                <div className="flex justify-between items-start">
                    <h4 className="text-2xl font-semibold text-secondary">Profile Details</h4>
                    <button
                        onClick={() => setIsEditProfile(true)}
                        className="text-base font-semibold min-w-[5.5rem] min-h-8 flex items-center justify-center px-6 py-1.5 bg-secondary border border-secondary text-primary rounded-lg md:rounded-[10px]">
                        Edit Profile
                    </button>
                </div>
                <div className="flex flex-col gap-x-10 gap-y-3 py-10">
                    <div className="flex flex-col gap-3">
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Business Name</p>
                            <p className='text-detail'>{user?.restaurant?.name}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Email Address</p>
                            <p className='text-detail break-words line-clamp-2'>{user?.restaurant?.email}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Main point of contact</p>
                            <p className='text-detail'>{user?.restaurant?.authorized_manager ? user?.restaurant?.authorized_manager : "-----"}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Mobile Number</p>
                            <p className='text-detail'>{user?.mobile_number && user?.mobile_number !== 'null' && user?.mobile_number !== 'undefined' ? `+${user?.mobile_code} ${user?.mobile_number}` : "-----"}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Phone Number</p>
                            <p className='text-detail'>{user?.phone_number && user?.phone_number !== 'null' && user?.phone_number !== 'undefined' ? `+${user?.phone_code} ${user?.phone_number}` : "-----"}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Finance Email</p>
                            <p className='text-detail'>{user?.restaurant?.finance_email && user?.restaurant?.finance_email !== 'null' && user?.restaurant?.finance_email !== 'undefined' ? user?.restaurant?.finance_email : "-----"}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 ">
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Categories</p>
                            <p className='text-detail'>{user?.restaurant?.categories?.length > 0
                                ? user?.restaurant?.categories?.map(category => category?.name).join(', ')
                                : 'No categories available'}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Sub Category</p>
                            <p className='text-detail'>{user?.restaurant?.cuisine?.name}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Website Link</p>
                            <p className='text-detail'>{user?.restaurant?.website_link && user?.restaurant?.website_link != 'null' && user?.restaurant?.website_link != 'undefined' ? user?.restaurant?.website_link : "-----"}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Instagram Link</p>
                            <p className='text-detail'>{user?.restaurant?.instagram_link && user?.restaurant?.instagram_link != 'null' && user?.restaurant?.instagram_link != 'undefined' ? user?.restaurant?.instagram_link : "-----"}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>WhatsApp Number</p>
                            <p className='text-detail'>{user?.wa_number && user?.wa_number !== 'null' && user?.wa_number !== 'undefined' ? `+${user?.wa_code == 'null' ? user?.mobile_code : user?.wa_code} ${user?.wa_number}` : "-----"}</p>
                        </div>
                        <div className="flex gap-10 items-start">
                            <p className='text-head'>Price Cover</p>
                            <p className='text-detail'>{user?.restaurant?.price_per_cover ? `${user?.restaurant?.price_per_cover} ${currency}` : "-----"}</p>
                        </div>
                    </div>
                    <div className="flex gap-3 xs:gap-10 items-start flex-col xs:flex-row">
                        <p className='text-head'>Address</p>
                        <p className='text-detail'>{user?.restaurant?.address}</p>
                    </div>
                    <div className="grid xl:grid-cols-2 grid-cols-1 gap-3 items-start">
                        <div className="flex flex-col gap-3 min-w-80">
                            <h1 className='text-base text-white'>Trade Licence</h1>
                            {user?.restaurant?.trade_license ?
                                <ImageUploader value={user?.restaurant?.trade_license} hideEdit />
                                : <div className='w-full max-w-80 text-blueGrey'>
                                    No attachement
                                </div>
                            }
                            {/* <Image src={user?.restaurant?.trade_license} className={'h-52 w-full max-w-80 view-edit-btn p-2 rounded-lg'} /> */}
                        </div>
                        <div className="flex flex-col gap-3 min-w-80">
                            <h1 className='text-base text-white'>Partnership Agreement</h1>
                            {user?.restaurant?.agreement ?
                                <ImageUploader value={user?.restaurant?.agreement} hideEdit />
                                : <div className='w-full max-w-80 text-blueGrey'>
                                    No attachement
                                </div>
                            }
                            {/* <Image src={user?.restaurant?.agreement} className={'h-52 w-full object-fit max-w-80 view-edit-btn p-2 rounded-lg'} /> */}
                        </div>
                    </div>
                    <div className="flex gap-3 items-start flex-col">
                        <p className='text-head capitalize'>Description</p>
                        <p className='text-detail whitespace-pre-wrap'>{user?.restaurant?.description ? user?.restaurant?.description : "-----"}</p>
                    </div>
                    <div className="flex gap-3 items-start flex-col">
                        <p className='text-head capitalize'>Terms and conditions</p>
                        <p className='text-detail whitespace-pre-wrap'>{user?.restaurant?.terms_and_conditions ? user?.restaurant?.terms_and_conditions : "-----"}</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ProfileDetails