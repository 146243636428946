import PaymentCheckboxCard from 'Components/Elements/PaymentCheckboxCard'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAddMenu from './helper'
import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import MenuItem from './MenuItem'
import AddMenuItem from './AddMenuItem'
import FilePicker from 'Components/Elements/FilePicker'
import Svgs from 'Assets/svgs'

const AddMenuPopup = ({ open, close, onSuccessCallback, type }) => {
    const {
        menuType, setMenuType, manuItem, setMenuItem, errors, loading, setErrors, file, setFile,
        onAddManualManu, openItems, setOpenItems, onAddSubMenu, onChange, subMenuItems, setLoading, setSubMenuItems
    } = useAddMenu(close, onSuccessCallback, open, type)

    return (
        <>
            <Popup
                open={open}
                close={() => {
                    if (openItems) {
                        setOpenItems(false)
                        setLoading(false)
                        setSubMenuItems()
                    } else {
                        close(false)
                        setMenuItem({ type: type ? type : 'main_menu', name: '', })
                        setMenuType()
                        setFile()
                    }
                }}
                heading={"Add Main Menu"}
                size={'xs'}
            >
                {openItems
                    // Show when add sub menu for category
                    ? <AddMenuItem
                        errors={errors}
                        onChange={onChange}
                        formData={subMenuItems}
                        mainMenu={openItems}
                        onSubmit={() => {
                            onAddSubMenu(() => {
                                setOpenItems(false)
                                setSubMenuItems()
                                setMenuItem({ type: type ? type : 'main_menu', name: '', })
                                setMenuType()
                                setFile()
                            })
                        }}
                        loading={loading}
                    />
                    // Show when Adding Menu Category
                    :
                    <div className="flex flex-col gap-5">
                        {!open?.id
                            && <div className="grid grid-cols-2 gap-4">
                                <PaymentCheckboxCard
                                    icon={<Svgs.UploadCloud />}
                                    customMenuStyle
                                    checked={menuType == 'pdf'}
                                    onClick={() => { setMenuType('pdf') }}
                                    id={'pdf'}
                                    padding={'!pr-0'}
                                    title={'Upload PDF'}
                                />
                                <PaymentCheckboxCard
                                    icon={<Svgs.CloseBook />}
                                    checked={menuType == 'manual'}
                                    onClick={() => { setMenuType('manual') }}
                                    id={'manual'}
                                    disabled={open?.type == 'edit'}
                                    padding={'!pr-0'}
                                    title={'Add Manually'}
                                />
                            </div>
                        }

                        {menuType == 'pdf'
                            // PDF menu
                            ? <div className='flex w-full flex-col gap-6'>
                                <FilePicker
                                    value={file}
                                    customHeight="w-full"
                                    onChange={(e) => {
                                        setFile(e?.target?.files[0])
                                    }}
                                    accept="application/pdf"
                                    title="Upload your PDF"
                                    error={errors?.upload_menu}
                                // description="Browse"
                                // descText={'underline font-semibold text-[#1d8f70]'}
                                />
                                <Button
                                    title={'Upload'}
                                    loading={loading}
                                    onClick={onAddManualManu}
                                />
                            </div>
                            // Manual Menu
                            : menuType == 'manual' &&
                            <div div className='flex w-full flex-col gap-6'>
                                {/* <div className={`relative flex flex-col gap-2 `}>
                                    <div className={`text-sm text-blueGrey flex items-center gap-1`}>
                                        Type <span className="leading-[1] text-red-600"> *</span>
                                    </div>
                                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                                        <div className={`border ${manuItem?.type == 'main_menu' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => {
                                            setMenuItem({
                                                ...manuItem,
                                                type: 'main_menu'
                                            })
                                        }}>
                                            <RadioBtn name={'Main Menu'} text={"Main Menu"} checked={manuItem?.type == 'main_menu'} id={'Main Menu'} />
                                        </div>
                                        <div className={`border ${manuItem?.type == 'beverage_menu' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => {
                                            setMenuItem({
                                                ...manuItem,
                                                type: 'beverage_menu'

                                            })
                                        }}>
                                            <RadioBtn name={'Beverage Menu'} text={"Beverage Menu"} checked={manuItem?.type == 'beverage_menu'} id={'Beverage Menu'} />
                                        </div>
                                        <div className={`border ${manuItem?.type == 'dessert_menu' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => {
                                            setMenuItem({
                                                ...manuItem,
                                                type: 'dessert_menu'

                                            })
                                        }}>
                                            <RadioBtn name={'Dessert Menu'} text={"Dessert Menu"} checked={manuItem?.type == 'dessert_menu'} id={'Dessert Menu'} />
                                        </div>
                                    </div>
                                </div> */}
                                <LoginInput
                                    error={errors?.name}
                                    value={manuItem?.name}
                                    title={manuItem?.type == 'main_menu' ? 'Menu name' : manuItem?.type == 'beverage_menu' ? 'Beverage name' : 'Dessert name'}
                                    name={'name'}
                                    placeholder={`Enter ${manuItem?.type == 'main_menu' ? 'menu name' : manuItem?.type == 'beverage_menu' ? 'beverage name' : 'dessert name'}`}
                                    onChange={(e) => {
                                        setErrors({
                                            ...errors,
                                            [e?.target?.name]: ''
                                        })
                                        setMenuItem({
                                            ...manuItem,
                                            [e?.target?.name]: e?.target?.value
                                        })
                                    }}
                                    disabled={open?.id}
                                    specialCharacterNotAllowed={true}
                                />

                                {/* Show SUb menu only when editing  */}
                                {open?.id && <MenuItem data={open} setOpenItems={setOpenItems} />}

                                <Button
                                    title={open?.type
                                        ? "Save"
                                        : 'Add'
                                    }
                                    loading={loading}
                                    onClick={onAddManualManu}
                                />

                            </div>
                        }
                    </div>
                }
            </Popup>
        </>
    )
}

export default AddMenuPopup