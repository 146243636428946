import { deleteAccount, makeRestaurantIsNewFalse, updateUser } from 'Adapters/APIs/Authentication'
import { getAllCategories, getRestaurantBusinessHours, postRestaurantBusinessHours } from 'Adapters/APIs/restaurants'
import useLogoutHelper from 'Hooks/useLogoutHelper'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import useValidations from 'Hooks/useValidations'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

const useProfile = () => {
    const { logoutUser } = useLogoutHelper()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const user = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')

    const { setErrors, errors, validateWebsiteUrl } = useValidations()

    // states
    const [isEditProfile, setIsEditProfile] = useState(false)
    const [activeTab, setActiveTab] = useState(searchParams.get('activeTab') || 'profile')
    const [openDropDownMenu, setopenDropDownMenu] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openRatingPopup, setOpenRatingPopup] = useState(false)
    const [ratingData, setRatingData] = useState([
        {
            type: "tripadvisor",
            rating: "",
            link: ""
        }, {
            type: "google",
            rating: "",
            link: ""
        }
    ])
    const [deletePopup, setDeletePopup] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [businessHourLoader, setBusinessHourLoader] = useState(false)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)

    const handleSubmit = () => {
        const success = (data) => {
            storeDataInLocalStorage('user_data', data);
            setOpenRatingPopup(false);
            setLoading(false);
        };

        const fail = () => {
            setLoading(false);
        };

        // Initialize error state
        let updatedErrors = {};
        let hasError = false;

        // Check if ratingData is empty
        if (!ratingData || ratingData.length === 0) {
            let newData = [{
                type: "tripadvisor",
                rating: ""
            }, {
                type: "google",
                rating: ""
            }];
            // Process each item in newData
            newData?.forEach((item) => {
                if (!item.link || item.link.trim() === "") {
                    updatedErrors[item.type + '_link'] = "Please enter a valid URL for " + item.type;
                    hasError = true;
                }
                if (!item.rating || item.rating === '0') {
                    updatedErrors[item.type + '_rating'] = "Please provide a valid rating for " + item.type;
                    hasError = true;
                }
            });
            hasError = true; // Since all data is invalid, set error to true
        } else {
            let foundValidItem = false; // Flag to track if an object with both link and rating is found

            // Process each item in ratingData
            ratingData.forEach((item) => {
                const hasLink = item?.link && item?.link?.trim() !== "";
                const hasRating = item?.rating && item?.rating !== '0';

                // If both link and rating are present, set the flag to true
                if (hasLink && hasRating) {
                    foundValidItem = true;
                }

                // Validate link and rating
                if (hasLink && !hasRating) {
                    updatedErrors[item.type + '_rating'] = "Please provide a valid rating for " + item.type;
                    hasError = true;
                } else if (hasRating && !hasLink) {
                    updatedErrors[item.type + '_link'] = "Please enter a valid URL for " + item.type;
                    hasError = true;
                }
            });

            // Check if at least one valid item exists
            if (!foundValidItem) {
                ratingData.forEach((item) => {
                    if (!item.rating || item.rating === '0') {
                        updatedErrors[item.type + '_rating'] = "Please provide a valid rating for " + item.type;
                        hasError = true;
                    }
                    if (!item.link || item.link.trim() === "") {
                        updatedErrors[item.type + '_link'] = "Please enter a valid URL for " + item.type;
                        hasError = true;
                    }
                });
            }
        }

        if (hasError) {
            setErrors(updatedErrors);
            return; // Prevent form submission if there are validation errors
        } else {
            setLoading(true);
            dispatch(updateUser(access_token, { ratings: ratingData, restaurant_id: user?.restaurant?.id ? user?.restaurant?.id : "" }, success, fail));
        }
    };


    // delete account
    const handleDeleteAccount = () => {
        const onSuccessCallback = () => {
            setDeletePopup(false)
            setDeleteLoader(false)
        }
        const success = (data) => {
            logoutUser(onSuccessCallback)
        }
        const fail = () => {
            setDeleteLoader(false)
        }
        setDeleteLoader(true)
        dispatch(deleteAccount(access_token, {}, success, fail))
    };

    // Function to transform business hours
    const transformBusinessHours = (data) => {
        return Object.entries(data)?.length > 0 ? Object.entries(data).map(([day_of_week, { opening_time, closing_time }]) => ({
            day_of_week,
            opening_time,
            closing_time
        })) : [];
    };

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    // post business hours
    const postBusinessHours = (data) => {
        const transformedData = transformBusinessHours(data);
        const success = () => {
            setBusinessHourLoader(false)
        }
        const fail = () => {
            setBusinessHourLoader(false)
        }
        setBusinessHourLoader(true)
        dispatch(postRestaurantBusinessHours(access_token, { restaurant_id: user?.restaurant?.id ? user?.restaurant?.id : "", hours: transformedData }, success, fail))
    }

    useEffect(() => {
        if (user?.restaurant?.ratings?.length > 0) {
            setRatingData(user?.restaurant?.ratings?.map(itm => ({
                type: itm?.type,
                rating: itm?.rating,
                link: itm?.link
            })))
        }
    }, [])

    useEffect(() => {
        getCategories()

        if (user?.restaurant?.is_new == 1) {
            dispatch(makeRestaurantIsNewFalse(access_token, { restaurant_id: user?.restaurant?.id }, () => { }, () => { }))
            Cookies.set('getUserProfileData', true)
            navigate('/restaurant/profile?activeTab=profile');
        }
    }, [])

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        // Check if the element with class '.topscroll' exists
        const topScrollElement = document.querySelector(".edittopscroll");

        if (topScrollElement) {
            // Scroll the element into view
            topScrollElement.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.warn('.topscroll element not found');
        }
    }, [isEditProfile, activeTab]);

    useEffect(() => {
        // Fetch data only if we're on the profile page and have an active tab
        if (activeTab && window.location.pathname === "/restaurant/profile") {
            // Fetch restaurant business hours
            dispatch(getRestaurantBusinessHours(access_token, {
                restaurant_id: user?.restaurant?.id || ""
            }));

            // Get the current URL params
            const params = new URLSearchParams(window.location.search);

            // Only push new state if the activeTab has actually changed
            if (params.get('activeTab') !== activeTab) {
                const newUrl = `/restaurant/profile?activeTab=${activeTab}`;
                window.history.pushState(null, '', newUrl);  // Push the URL with the activeTab
            }
        }

        const handlePopState = () => {
            const params = new URLSearchParams(window.location.search);
            const tabFromUrl = params.get('activeTab');

            if (!tabFromUrl) {
                navigate(-1);  // Navigate to the previous page if no activeTab
            } else {
                setActiveTab(tabFromUrl);  // Update the activeTab state based on the URL
            }
        };

        // Listen for popstate (back button)
        window.addEventListener('popstate', handlePopState);

        // Clean up the listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [activeTab, access_token, user?.restaurant?.id, dispatch, navigate]);


    return {
        user, navigate, isEditProfile, setIsEditProfile, activeTab, setActiveTab, openDropDownMenu, setopenDropDownMenu,
        openRatingPopup, setOpenRatingPopup, loading, ratingData, setRatingData, handleSubmit, errors, setErrors, validateWebsiteUrl,
        deletePopup, setDeletePopup, handleDeleteAccount, deleteLoader, postBusinessHours, businessHourLoader
    }
}

export default useProfile
