import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import React from 'react'
import useInviteFriendHelper from './helper'
import InviteHistoryCard from './Elements/InviteHistoryCard'
import EarningHistoryCard from './Elements/EarningHistoryCard'
import RedeemHistoryCard from './Elements/RedeemHistoryCard'
import { currency } from 'Adapters/variables'
import NotFound from 'Utility/NotFound'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import DeletePopup from 'Pages/Restaurants/Menu/DeleteVerificationPopup'
import moment from 'moment'

const InviteFriend = () => {
    const { activeTab, setActiveTab, loading, currentPage, setCurrentPage, user_data, history, pagination_data, secondLoader,
        handleSubmitReferral, email, setEmail, emailLoader, handleResendReferral, resendLoader, handleDeletePendingReferral,
        deleteLoader, redeemClick, setRedeemClick, redeemLoader, redeemReferAmount } = useInviteFriendHelper()
    return (
        <DashboardLayout active={'InviteFriend'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                <div className="pt-4 flex justify-between items-center">
                    <Back
                        title={'Invite Friend'}
                    />
                </div>
                <div className="py-6">
                    {loading ?
                        <div className='bg-gray-500 animate-pulse rounded-md h-72'></div>
                        :
                        <div className='bg-darkGrey rounded-md py-4 px-2 items-center'>
                            <div className='flex flex-col space-y-8'
                                style={{
                                    backgroundImage: "url('/img/InviteFriendBg.png')",
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '280px',
                                    width: '100%',
                                }}>
                                <div className='flex flex-col items-center justify-center mx-auto space-y-4 max-w-md'>
                                    <h2 className='text-center text-white text-xl pt-4'>Refer your friend and <br /> earn more</h2>
                                    <div className='rounded-full border-2 border-lightGrey grid grid-cols-3 justify-between items-center gap-2'>
                                        <h2 className='text-secondary text-xl py-2 px-4 col-span-2 font-semibold'>{user_data?.wallet} {currency}</h2>
                                        <Button
                                            className={'rounded-full !text-sm'}
                                            text={'Redeem'}
                                            disabled={user_data?.wallet == '0.00'}
                                            onClick={() => {
                                                setRedeemClick(!redeemClick)
                                            }}
                                        />
                                    </div>
                                </div>
                                <p className='text-sm text-blueGrey pl-4'>Refer a friend and earn 15 AED for each successful billed booking they make. You can earn up to 150 AED from one referral.</p>
                                <div className='rounded grid grid-cols-3 justify-between items-center max-w-md mx-auto'>
                                    <div className='col-span-2 w-full h-full'>
                                        <LoginInput
                                            title={''}
                                            showBorder={false}
                                            placeholder={'Enter email address'}
                                            className={"border-none outline-none focus:!border-none"}
                                            inputPadding={"px-4 py-3"}
                                            customColor={"bg-lightGrey"}
                                            customRounded={"!rounded-l-lg"}
                                            value={email}
                                            onChange={(e) => { setEmail(e?.target?.value) }}
                                        />
                                    </div>
                                    <Button
                                        customThemeBtn={'text-primary rounded-r-lg text-sm shadow transition duration-150 ease-in-out flex items-center justify-center whitespace-nowrap font-semibold bg-secondary'}
                                        customPadding={"py-3.5"}
                                        text={'Send'}
                                        disabled={!email || emailLoader}
                                        loading={emailLoader}
                                        onClick={handleSubmitReferral}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="py-6">
                        <div className="border-b border-lightGrey flex items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setActiveTab('invite-history')} className={`${activeTab == 'invite-history' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                Invite History
                            </p>
                            <p onClick={() => setActiveTab('earning-history')} className={`${activeTab == 'earning-history' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                Earning History
                            </p>
                            <p onClick={() => setActiveTab('redeem-history')} className={`${activeTab == 'redeem-history' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                Redeem History
                            </p>
                        </div>
                        <div className="py-6">
                            {loading ?
                                <div className="flex flex-col space-y-4">
                                    <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                                        {[0, 1, 2, 3, 4]?.map(itm => {
                                            return <div className='bg-gray-500 animate-pulse rounded-md h-32'></div>
                                        })}
                                    </div>
                                </div>
                                :
                                <div className='flex flex-col space-y-4'>
                                    {history?.length > 0 ?
                                        <InfiniteScroll
                                            dataLength={history?.length ? history?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                setCurrentPage(currentPage + 1)
                                            }}
                                            hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                            loader={secondLoader
                                                ? <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div> : ""
                                            }
                                        // height={'40rem'}
                                        >
                                            <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                                                {history?.map((itm, index) =>
                                                    activeTab == 'invite-history' ?
                                                        <InviteHistoryCard
                                                            status={itm?.user_id ? 'Accepted' : "Pending"}
                                                            email={!itm?.user_id ? itm?.email : `${itm?.user?.first_name ? itm?.user?.first_name : ""} ${itm?.user?.last_name ? itm?.user?.last_name : ""}`}
                                                            onResendSubmit={handleResendReferral}
                                                            id={itm?.id}
                                                            resendLoader={resendLoader}
                                                            onDeleteSubmit={handleDeletePendingReferral}
                                                            deleteLoader={deleteLoader}
                                                        /> :
                                                        activeTab == 'earning-history' ?
                                                            <EarningHistoryCard
                                                                name={`${itm?.user?.first_name ? itm?.user?.first_name : ""} ${itm?.user?.last_name ? itm?.user?.last_name : ""}`}
                                                                doneValue={itm?.reservation_count}
                                                                earning={itm?.earning}
                                                                type={itm?.user?.restaurant ? 'Business' : "Concierge"}
                                                            />
                                                            : <RedeemHistoryCard
                                                                status={itm?.approved_at ? 'Accepted' : 'Pending'}
                                                                amount={itm?.amount}
                                                                created_at={moment(itm?.created_at).format('DD MMMM YYYY')}
                                                                approved_at={itm?.approved_at ? moment(itm?.approved_at).format('DD MMMM YYYY') : "----"}
                                                            />
                                                )}
                                            </div>
                                        </InfiniteScroll>
                                        : <NotFound />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </main>

            <DeletePopup
                open={redeemClick}
                close={setRedeemClick}
                title={<div className='flex flex-col space-y-4 mx-auto items-center justify-center pb-4 text-center'>
                    <h2 className='text-white'>Are you sure you want to Redeem?</h2>
                </div>}
                loading={redeemLoader}
                onDelete={redeemReferAmount}
            />
        </DashboardLayout>
    )
}

export default InviteFriend