import React from 'react'
import Shimmer from './Shimmer'

const ConceirgeCardShimmer = ({ title2, title3, shimmerStyle, icon,cardRowStyle, showViewDetail, customPadding, showAmount1, showAmount2, showAmount3, className }) => {
    return (
        <Shimmer className={className}>
            <div className={`relative bg-darkGrey p-4 rounded-[10px] cursor-pointer ${shimmerStyle ? shimmerStyle : ''}`}>
                {showViewDetail &&
                    <div className='absolute right-0 top-0 bg-gray-500 py-[18px] px-[45px] rounded-bl-[20px] rounded-tr-[20px] text-sm font-bold'>
                    </div>
                }
                <div className={`flex justify-between w-full text-blueGrey gap-4 ${customPadding ? customPadding : "pt-10 px-2 py-6"} ${cardRowStyle ? cardRowStyle : ""}`}>
                    <div class="rounded-[20px] p-4 bg-gray-500 space-y-3 flex-1">
                        <div className="bg-gray-400 h-3 w-20 rounded-md"></div>
                        <div class="flex items-center gap-3">
                            {icon &&
                                <div class="size-9 min-w-9 min-h-9 flex bg-gray-400 items-center rounded-[10px] justify-center"></div>
                            }
                            {showAmount1 &&
                                <div className="bg-gray-400 h-3 w-10 rounded-md"></div>
                            }
                        </div>
                    </div>
                    {(title2) &&
                        <>
                            <div class="rounded-[20px] p-4 bg-gray-500 space-y-3 flex-1">
                                <div className="bg-gray-400 h-3 w-20 rounded-md"></div>
                                <div class="flex items-center gap-3">
                                    {icon &&
                                        <div class="size-9 min-w-9 min-h-9 flex bg-gray-400 items-center rounded-[10px] justify-center"></div>
                                    }
                                    {showAmount2 &&
                                        <div className="bg-gray-400 h-3 w-10 rounded-md"></div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                    {(title3) &&
                        <>
                            <div class="rounded-[20px] p-4 bg-gray-500 space-y-3 flex-1">
                                <div className="bg-gray-400 h-3 w-20 rounded-md"></div>
                                <div class="flex items-center gap-3">
                                    {icon &&
                                        <div class="size-9 min-w-9 min-h-9 flex bg-gray-400 items-center rounded-[10px] justify-center"></div>
                                    }
                                    {showAmount3 &&
                                        <div className="bg-gray-400 h-3 w-10 rounded-md"></div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Shimmer>
    )
}

ConceirgeCardShimmer.defaultProps = {
    showViewDetail: true,
    showAmount1: true,
    showAmount2: true,
    showAmount3: true
}

export default ConceirgeCardShimmer