import { readSingleNotification } from 'Adapters/APIs/Authentication'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const useNotificationRedirectionHelper = (setOpen) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { getDataFromLocalStorage, storeDataInSessionStorage } = useSetDataInLocalStorage()

    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // read single Notification
    const updateRead = async (id, isReload, type) => {
        if (isReload) {
            if (type === 'booking') {
                storeDataInSessionStorage('NotificationId', id)
            }
        } else {
            let success = () => {
                setOpen(false)
            }
            let fail = () => {
                setOpen(false)
            }
            dispatch(readSingleNotification(access_token, id, success, fail))
        }
    }

    // get default route data
    function getUpdatedDefaultRoute() {
        const defaultRoute = localStorage.getItem("defaultRoute");

        if (defaultRoute) {
            // Remove leading slashes and split by '/'
            const segments = defaultRoute.replace(/^\/+/, '').split('/');
            // Check if the second segment is available (after '/dashboard')
            return segments[1] || ''; // Get the segment immediately after '/dashboard'
        }

        // Return a default value if no valid route is found
        return '';
    }

    // Notificaions Redirections
    const redirectNotifications = (id, type, data, completeData) => {
        const updatedDefaultRoute = getUpdatedDefaultRoute();
        const notificationRestaurantId = completeData?.restaurant_id;

        let clickActionUrl = '/';
        switch (type) {
            case 'reservation_alert':
            case 'reservation_update_alert':
            case 'reservation_cancelled':
            case 'reservation_modified':
            case 'reservation_modified_confirmed':
            case 'reservation_confirmed':
            case 'reservation_no_show':
            case 'restaurant_request_alert':
            case 'bill_alert':
                updateRead(id)
                storeDataInSessionStorage('booking_id', data)
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/booking`)) {
                    window.location.href = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`
                } else {
                    clickActionUrl = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`
                }
                setOpen(false)
                break;
            case 'commission_paid_alert':
                updateRead(id)
                storeDataInSessionStorage('booking_id', data)
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/commission`)) {
                    window.location.href = `/${updatedDefaultRoute}/commission`
                } else {
                    clickActionUrl = `/${updatedDefaultRoute}/commission`
                }
                setOpen(false)
                break;
            case 'commission_confirmation_alert':
                updateRead(id)
                storeDataInSessionStorage('booking_id', data)
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/payment`)) {
                    window.location.href = `/${updatedDefaultRoute}/payment`
                } else {
                    clickActionUrl = `/${updatedDefaultRoute}/payment`
                }
                setOpen(false)
                break;

            case 'boost_request_approved':
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/boosting-history`)) {
                    window.location.href = `/${updatedDefaultRoute}/boosting-history/?slot_id=${completeData?.notification_data}`
                }
                clickActionUrl = `/${updatedDefaultRoute}/boosting-history/?slot_id=${completeData?.notification_data}`
                break;

            case 'membership_confirmation_alert':
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/invite-friend`)) {
                    window.location.href = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`
                }
                clickActionUrl = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`
                break;

            case 'withdraw_request_approved':
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/invite-friend`)) {
                    window.location.href = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`
                }
                clickActionUrl = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`;
                break;

            case 'reward_alert':
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/reward`)) {
                    window.location.href = `/${updatedDefaultRoute}/reward`
                }
                clickActionUrl = `/${updatedDefaultRoute}/reward`;
                break;

            case 'wishlist_update_alert':
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist`) || window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`)) {
                    window.location.href = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`
                }
                clickActionUrl = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`;
                break;

            case 'wishlist_assigned_alert':
                if (window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist`) || window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`)) {
                    window.location.href = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`
                }
                clickActionUrl = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`;
                break;


            case 'user_deactivate_alert':
                updateRead(id)
                storeDataInSessionStorage('booking_id', data)
                Cookies.set('logoutUser', true)
                setOpen(false)
                break;

            default:
                updateRead(id)
                storeDataInSessionStorage('booking_id', data)
                setOpen(false)
                clickActionUrl = '/'
                break;

        }
        if (notificationRestaurantId && (user_data?.restaurant?.id !== notificationRestaurantId)) {
            // Call function to show the popup
            Cookies.set('notificationClickUrl', clickActionUrl);
            Cookies.set('notificationRestaurantId', notificationRestaurantId);
        } else {
            navigate(clickActionUrl)
        }

    }

    return { redirectNotifications }
}

export default useNotificationRedirectionHelper