import { BASE_URL, add_thread_comment_url, create_support_request_url, get_concierge_support_req_url, get_singel_concierge_support_req_url, reopen_support_request_url } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getSupportReq } from "../../../Redux/Concierge/ConciergeDashboard/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";

// get all support requests
export const getConceirgeSuppReq = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_concierge_support_req_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            setLoading && setLoading(false)
            dispatch(getSupportReq(data))
        }
    } catch (error) {
        if(error?.response?.status ==404){
            dispatch(getSupportReq(error?.response?.data?.requests))
        }
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// get single request data
export const getSingleConceirgeSuppReq = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_singel_concierge_support_req_url}/${payload}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        // toast.error(apiErrorMessage)
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// reopen support request
export const reOpenSupportRequest = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${reopen_support_request_url}/${payload?.id}`, { message: payload?.message }, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message)
            const data = response?.data
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// add comment on support request
export const addThreadComments = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${add_thread_comment_url}/${payload?.id}`, { message: payload?.message }, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// create support request
export const createSupportRequest = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${create_support_request_url}`, formData, headers);
        if (response?.status == 200) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}