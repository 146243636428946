import React from 'react'
import DashboardLayout from '../../../Components/DashboardLayout'
import RestaurantCard from '../../Admin/MainDashbaord/Elements/RestaurantCard';
import useDashboard from './helper';
import Button from 'Components/Elements/Button';
import AdminRestaurantCardShimmer from 'Utility/Shimmers/AdminRestaurantCardShimmer';
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer';
import CreateBooking from '../Bookings/CreateBookings';
import { currency } from 'Adapters/variables';
import RestaurantFilter from 'Components/RestaurantFilterDropdown';
import ViewAllBtn from 'Components/Elements/ViewAllBtn';
import DateRangePicker from 'Components/Elements/ReactDateRange';
import DashboardCard from 'Components/ConceirgeCards/DashboardCard';
import RestaurantFilterCircleCard from './Elements/RestaurantFilterCircleCard';
import Svgs from 'Assets/svgs';
import NotFound from 'Utility/NotFound';

const ConceirgeDashbaord = () => {
    const { dashboardData, restaurants, promotions, navigate, openRestaurantPopup, setOpenRestaurantPopup, loading, resturantLoading,
        promotionloading, createBookingPopup, setCreateBookingPopup, startDate, setStartDate, endDate, setEndDate, storeRestaurant,
        storeDataInLocalStorage, getCategoriesLoader, categoriesData
    } = useDashboard()

    return (
        <>
            <DashboardLayout active={'Dashboard'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="pt-4 space-y-5">
                        <div className='flex justify-between items-center gap-2 pb-4'>
                            <h2 className="font-semibold md:text-2xl sm:text-lg text-md text-secondary">Dashboard</h2>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                maxDate={new Date()}
                                className={'!w-full'}
                            />
                        </div>
                        <div className="grid lg:grid-cols-5 grid-cols-1 lg:gap-4 lg:space-y-0 space-y-4 mb-4">
                            {loading
                                ? <>
                                    <ConceirgeCardShimmer title2={true} className={'col-span-2'} cardRowStyle={'xl:flex grid grid-cols-1'} icon={true} />
                                    <ConceirgeCardShimmer title2={true} title3={true} className={'col-span-3'} cardRowStyle={'xl:flex grid sm:grid-cols-2 grid-cols-1'} icon={true} />
                                </>
                                : <>
                                    <DashboardCard
                                        cardHeading={'Booking Details'}
                                        title1={'Total Bookings'}
                                        title2={'Upcoming Bookings'}
                                        icon1={<Svgs.BookingBagIcon width={22} height={22} />}
                                        icon2={<Svgs.UpcommingBookings width={22} height={22} />}
                                        customPadding={'pt-10 px-0 pb-0'}
                                        amount1={dashboardData?.total_bookings}
                                        amount2={dashboardData?.new_bookings}
                                        onAllClick={() => navigate('/concierge/booking')}
                                        btnText={"View Detail"}
                                        cardRowStyle={'xl:flex grid grid-cols-1'}
                                        hideCurrency
                                        hideCurrency2
                                        className={'bg-lightGrey col-span-2'}
                                        />
                                    <DashboardCard
                                        icon1={<Svgs.TotalEarned width={22} height={22} />}
                                        icon2={<Svgs.TotalReceived width={22} height={22} />}
                                        icon3={<Svgs.PendingPayments width={22} height={22} />}
                                        className={'bg-lightGrey col-span-3'}
                                        cardRowStyle={'xl:flex grid sm:grid-cols-2 grid-cols-1'}
                                        customPadding={'pt-10 px-0 pb-0'}
                                        cardHeading={'Payment Details'}
                                        title3={'Pending Payments'}
                                        title2={'Total Recieved'}
                                        title1={'Total Earned'}
                                        amount1={dashboardData?.total_earned}
                                        amount2={dashboardData?.total_recieved}
                                        amount3={dashboardData?.upcoming_payments}
                                        currency={currency}
                                        onAllClick={() => navigate('/concierge/commission')}
                                        btnText={"View Detail"}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className="py-6 grid grid-cols-1 lg:grid-cols-7 gap-5">
                        <div className="lg:col-span-7 space-y-5">
                            <div className="flex items-center justify-between">
                                {resturantLoading ?
                                    <>
                                        <div className='h-8 w-60 bg-gray-500 rounded-md'></div>
                                        <div className='h-8 w-20 bg-gray-500 rounded-md'></div>
                                    </>
                                    :
                                    <>
                                        <Button
                                            className="font-bold bg-transparent text-2xl text-secondary border-0 shadow-none"
                                            customPadding={'!p-2'}
                                            onClick={() => setOpenRestaurantPopup('restaurant')}
                                        >
                                            <div className='flex gap-2 justify-center items-center border-b border-solid border-secondary'>
                                                <p className='md:text-2xl text-base'>Choose a Business</p>
                                                <Svgs.ArrowDown width={'15'} height={'25'} fill={"var(--secondary-color)"} />
                                            </div>
                                        </Button>
                                        <ViewAllBtn
                                            onClick={() => { navigate('/concierge/restaurant') }}
                                            className={'!px-4'}
                                            title={'View All'}
                                        />
                                    </>}
                            </div>
                            {getCategoriesLoader ?
                                <div className='flex gap-5 items-center overflow-x-auto flex-nowrap'>
                                    {([0, 1, 2, 3, 4, 5, 6])?.map((itm, ind) => {
                                        return (
                                            <RestaurantFilterCircleCard
                                                key={ind}
                                                loading={getCategoriesLoader}
                                            />
                                        )
                                    })}
                                </div>
                                :
                                <div className='flex gap-5 items-stretch overflow-x-auto flex-nowrap'>
                                    {categoriesData?.map((itm, ind) => {
                                        return (
                                            <RestaurantFilterCircleCard
                                                text={itm?.name}
                                                key={ind}
                                                icon={itm?.icon}
                                                onClick={() => {
                                                    storeDataInLocalStorage('category', itm?.id)
                                                    navigate("/concierge/restaurant")
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                            }
                            <div className="gap-3 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1">
                                {resturantLoading
                                    && [0, 1, 2, 3, 4].map(index => (
                                        <AdminRestaurantCardShimmer showImages={true} hideInfo={true} hideStatus={true} bookBtn={true} key={index} />
                                    ))}
                            </div>
                            {!resturantLoading ?
                                restaurants?.length > 0 ?
                                    <div className="gap-3 grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1">
                                        {restaurants?.map((itm, ind) => {
                                            return <RestaurantCard
                                                showImages={true}
                                                hideInfo={true}
                                                hideStatus={true}
                                                data={itm}
                                                images={itm?.images}
                                                index={ind}
                                                name={itm?.name}
                                                phoneNumber={itm?.phone_number && itm?.phone_number != 'null' ? `+${itm?.phone_code} ${itm?.phone_number}` : itm?.mobile_number && itm?.mobile_number != 'null' ? `+${itm?.mobile_code} ${itm?.mobile_number}` : "----"}
                                                address={itm?.address}
                                                totalBookings={itm?.reservations}
                                                totalEarned={itm?.total_earned}
                                                totalDues={itm?.due_payments}
                                                active={itm?.is_active == 1 ? true : false}
                                                creditCardCheck={itm?.booking_cancellation_terms}
                                                onCardClick={() => { storeRestaurant(itm) }}
                                                bookBtn={<>
                                                    <Button
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            setCreateBookingPopup(itm?.id)
                                                        }}
                                                        customPadding={'!px-8 !py-2'}
                                                        text={'Book now'} className={'w-fit flex-end !text-xs'} />
                                                </>}
                                            />
                                        })}
                                    </div>
                                    : <NotFound />
                                : ""}
                        </div>
                        {/* <div className="-order-1 lg:order-none lg:col-span-5 space-y-5">
                            <div className="flex items-center justify-between">
                                <Button
                                    className="font-bold bg-transparent text-2xl text-white"
                                    customPadding={'p-2 '}
                                    onClick={() => setOpenRestaurantPopup('promotion')}
                                    title={'Choose a Promotion'}
                                />
                                <ViewAllBtn
                                    className={'!px-4'}
                                    onClick={() => navigate('/concierge/promotions')}
                                    title={'View All'}
                                />
                            </div>
                            <div className="space-y-3">
                                {promotionloading
                                    ? [0, 1, 2, 3, 4].map(index => (
                                        <PromotionCardShimmer key={index} />
                                    ))
                                    : promotions?.map(itm => {
                                        return <PromotonCard
                                            key={itm?.id}
                                            url={itm?.url}
                                            end_date={itm?.end_date}
                                            discount={itm?.discount}
                                            start_date={itm?.start_date}
                                            restaurantName={itm?.restaurant?.name}
                                            onClick={() => setCreateBookingPopup(itm?.restaurant?.id)}
                                        />
                                    })}
                            </div>
                        </div> */}
                    </div>
                </main>
                {createBookingPopup && <CreateBooking
                    selectRestaurant={createBookingPopup}
                    close={() => setCreateBookingPopup()}
                />}

                <RestaurantFilter
                    open={openRestaurantPopup}
                    close={setOpenRestaurantPopup}
                    openBooking={setCreateBookingPopup}
                />
            </DashboardLayout>
        </>
    )
}

export default ConceirgeDashbaord