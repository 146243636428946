import { getBusinessTypesData, postTradeLicense, registerUser } from "Adapters/APIs/Authentication";
import { getAllBusiness, getAllCategories, getAllCuisine } from "Adapters/APIs/restaurants";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify";

const initialState = {
    first_name: '',
    email: '',
    cuisine_id: '',
    phone_code: '',
    phone_number: '',
    mobile_code: '',
    mobile_number: '',
    type: 1, // 0 Concirege && 1 Resturent
    last_name: '',
    dob: '',
    business_name: '',
    payment_method: '',
    iban_number: '',
    card_holder_name: '',
    bank_name: '',
    password: '',
    restaurant_name: '',
    address: '',
    country: '',
    categories: [],
    state: '',
    city: '',
    location_coordinates: '',
    password_confirmation: '',
    authorized_manager: '',
    terms_condition: false,
    terms_and_conditions: '',
    privacy_policy: false,
    subscription_agreement: false,
    trade_license: ""
};

const useRegister = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { role } = useParams();

    const { storeDataInSessionStorage, getDataFromSessionStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const { errors, setErrors, validation, validateNumber, emailValidation, confirmPasswordValidation, passwordValidation } = useValidations()

    // states
    const [formData, setFormData] = useState(initialState);
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [showOTPScreen, setShowOTPScreen] = useState(false)
    const [addCuisine, setAddCuisine] = useState(false)
    const [getCuisineLoader, setGetCuisineLoader] = useState(true)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)
    const [addBusinessName, setAddBusinessName] = useState(false)
    const [getBusinessNameLoader, setBusinessNameLoader] = useState(true)
    const [businesses, setBusinesses] = useState([])
    const [businessTypes, setBusinessTypes] = useState([])
    const [loader, setLoader] = useState(true)
    const [existingCuisine, setExistingCuisine] = useState()
    const [roleValue, setRoleValue] = useState()

    // redux states
    const state = useSelector(state => state)
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories
    const businessData = state?.business?.businesses

    // setting cuisines array
    const cuisines = useMemo(() => {
        let allData = cuisinse?.map(itm => ({
            label: itm?.name,
            value: itm?.id
        })) || []; // Default to an empty array if cuisinse is undefined

        if (existingCuisine && existingCuisine?.value) {
            allData = [
                ...allData,
                {
                    label: existingCuisine.label,
                    value: existingCuisine.value
                }
            ];
        }

        if (formData?.categories?.length == 0) {
            allData = [];
        }

        // Remove duplicates based on the 'value' property
        const uniqueData = allData?.filter(
            (item, index, self) =>
                index === self?.findIndex((i) => i?.value === item?.value)
        );

        return uniqueData;
    }, [cuisinse, existingCuisine, formData?.categories]);


    // setting categories array
    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id,
                icon: itm?.icon,
                is_sub_category_required: itm?.is_sub_category_required,
                description: itm?.description
            }
        })
    }, [categoriesData])

    // setting isSubCategoryRequired check
    const isSubCategoryRequired = useMemo(() => {
        const selectedIds = formData?.categories || [];
        let filteredData = categories?.filter(category => selectedIds?.includes(category?.value));
        if (filteredData?.find(itm => itm?.is_sub_category_required == 1) && categories?.length > 0) {
            return true
        } else {
            return false
        }
    }, [categories, formData?.categories]);

    // setting selectedRole data
    const selectedRole = useMemo(() => {
        let business_types = businessTypes?.length > 0 ? businessTypes : [
            {
                "id": 1,
                "name": "Business",
                "slug": "business",
                "type": "restaurant",
                "status": 1
            },
            {
                "id": 2,
                "name": "Concierge",
                "slug": "concierge",
                "type": "concierge",
                "status": 1
            }
        ]
        setRoleValue(business_types?.find(itm => itm?.slug == role))
        return business_types?.find(itm => itm?.slug == role)?.type?.toLowerCase()
    }, [businessTypes, role])

    // by default data on every refresh
    useEffect(() => {
        if (!selectedRole || (selectedRole && selectedRole != 'restaurant' && selectedRole != 'concierge')) {
            navigate('/role-selection')
        }
        getCategories()
        getBusinessData()
        getBusinessTypes()
    }, [])

    // getting cuisines data on behalf of selected categories
    useEffect(() => {
        if (formData?.categories?.length > 0) {
            setGetCuisineLoader(true)
            getCuisineData()
        } else {
            setGetCuisineLoader(false)
            setFormData((prev) => ({
                ...prev,
                cuisine_id: ''
            }))
            setExistingCuisine()
        }
    }, [formData?.categories, formData?.categories?.length])

    // settting cuisine data
    useEffect(() => {
        if ((cuisines?.length > 0 && formData?.cuisine_id && !cuisines?.find(itm => itm?.value == formData?.cuisine_id)) || cuisines?.length == 0) {
            setFormData((prev) => ({
                ...prev,
                cuisine_id: ''
            }))
        }
    }, [cuisines, formData?.cuisine_id])

    useEffect(() => {
        if (existingCuisine && existingCuisine?.value && formData?.cuisine_id == '') {
            setFormData((prev) => ({
                ...prev,
                cuisine_id: existingCuisine?.value
            }))
        }
    }, [existingCuisine, formData?.cuisine_id])

    // change data on base of role
    useEffect(() => {
        let signupData = getDataFromSessionStorage("signupData");
        if (signupData) {
            setFormData(signupData)
        } else {
            setFormData(initialState)
        }
        setErrors()
        setStep(1)
    }, [role])

    // get business types
    const getBusinessTypes = () => {
        const success = (data) => {
            setLoader(false)
            if (data?.data?.business_types?.length > 0) {
                setBusinessTypes(data?.data?.business_types?.slice(0, 2))
            }
        }
        const fail = () => {
            setLoader(false)
        }
        setLoader(true)
        dispatch(getBusinessTypesData(success, fail))
    }

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'phone_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                mobile_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                phone_number: value,
                phone_code: value?.split('-')[0]?.replace('+', '')
            })
        }
        if (name == 'mobile_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                phone_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                mobile_number: value,
                mobile_code: value?.split('-')[0]?.replace('+', '')
            })
        }

        if (name == 'email') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            emailValidation(name, value);
            setFormData({
                ...formData,
                [name]: value
            })
        }

        if (name == 'password') {
            passwordValidation(name, value, formData.password_confirmation);
            setFormData({
                ...formData,
                [name]: value
            })
        }
        if (name == 'password_confirmation') {
            confirmPasswordValidation(name, value, formData.password)
            setFormData({
                ...formData,
                [name]: value
            })
        }
        if (name == 'trade_license') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.files[0]
            })
        }
        if (name == 'privacy_policy' || name == 'terms_condition' || name == 'subscription_agreement') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        if (name != 'phone_number' && name != 'mobile_number' && name != 'email' && name != 'password' && name != 'password_confirmation'
            && name != 'privacy_policy' && name != 'terms_condition' && name != 'subscription_agreement' && name != 'trade_license') {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    // post trade license
    const postTradeLicenseData = (data) => {
        dispatch(postTradeLicense({ id: data?.restaurant?.id, trade_license: formData?.trade_license }))
    }

    // get cuisine
    const getCuisineData = (existingId, existingName) => {
        const success = () => {
            if (existingId) {
                setFormData({
                    ...formData,
                    'cuisine_id': existingId
                })
                setErrors((prev) => ({
                    ...prev,
                    'cuisine_id': '',
                }));
                setExistingCuisine({
                    label: existingName,
                    value: existingId
                })
            }
            setAddCuisine(false)
            setGetCuisineLoader(false)
        }
        const fail = () => {
            setGetCuisineLoader(false)
        }
        let payload = {
            category_id: `[${formData?.categories}]`,
            cuisine_id: formData?.cuisine_id
        }
        setGetCuisineLoader(true)
        dispatch(getAllCuisine(payload, success, fail))
    }

    // get business
    const getBusinessData = () => {
        const success = (data) => {
            setAddBusinessName(false)
            setBusinessNameLoader(false)
            setBusinesses(data)
        }
        const fail = () => {
            setBusinessNameLoader(false)
        }
        setBusinessNameLoader(true)
        dispatch(getAllBusiness({ type: 'non_paginated' }, success, fail))
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    // handle submit form
    const handleSubmit = () => {
        let requiredFields = {}
        const { first_name, email,
            phone_number, mobile_code, mobile_number, last_name, dob,
            business_name, payment_method, iban_number, card_holder_name,
            bank_name, password, restaurant_name, address, country, categories,
            state, city, password_confirmation, cuisine_id,
            terms_condition, privacy_policy, subscription_agreement, trade_license,
            contact_person
        } = formData

        let payload = { ...formData }
        if (selectedRole == 'restaurant') {
            requiredFields = {
                restaurant_name,
                categories: categories?.length == 0 ? false : true,
                // authorized_manager,
                email,
                phone_number: phone_number ? phone_number : mobile_number ? true : false,
                phone_code: phone_number ? phone_number : mobile_number ? true : false,
                mobile_code: mobile_number ? mobile_number : phone_number ? true : false,
                mobile_number: mobile_number ? mobile_number : phone_number ? true : false,
                address, state, country, city,
                password, password_confirmation, trade_license,
                terms_condition, privacy_policy,
                contact_person
            }
            if (isSubCategoryRequired) {
                requiredFields = {
                    ...requiredFields,
                    cuisine_id: cuisine_id
                }
            }
        }
        if (selectedRole == 'concierge') {
            if (step == 1) {
                requiredFields = {
                    dob,
                    email,
                    last_name,
                    first_name,
                    business_name,
                    mobile_code, mobile_number,
                    address, state, country, city,
                    terms_condition, privacy_policy, subscription_agreement,
                }
            } else {
                requiredFields = { password, password_confirmation, payment_method }
                if (payment_method == 'bank') {
                    requiredFields = { ...requiredFields, iban_number, card_holder_name, bank_name, }
                }
                formData.type = '0'
            }
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (step == 1) {
                if (objectKeyConvertToArray(result)?.length === 0) {
                    setStep(2)
                    return
                }
                return
            }
        }
        passwordValidation("password", password, password_confirmation);
        confirmPasswordValidation("password_confirmation", password_confirmation, password);
        setErrors((prev) => ({
            ...prev,
            ...validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, })
        }));
        let result = validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, });
        if (objectKeyConvertToArray(result)?.length === 0 && !errors?.password_confirmation) {
            const success = (data) => {
                if (selectedRole != 'concierge') {
                    postTradeLicenseData(data)
                }
                storeDataInSessionStorage('signupData', { ...formData, user_id: data?.id })
                // setShowOTPScreen(true)
                navigate('/otp-verification')
                setLoading(false)
            }
            const fail = (error) => {
                setLoading(false)
                if (error?.response?.data?.status == 422) {
                    if (error?.response?.data?.error?.email || error?.response?.data?.error?.mobile_number) {
                        setErrors((prev) => ({
                            ...prev,
                            'email': error?.response?.data?.error?.email ? error?.response?.data?.error?.email[0] : false,
                            'mobile_number': error?.response?.data?.error?.mobile_number ? error?.response?.data?.error?.mobile_number[0] : false,
                            'phone_number': error?.response?.data?.error?.phone_number ? error?.response?.data?.error?.phone_number[0] : false
                        }));
                        setStep(1)
                    }
                }
            }
            if (payload?.phone_number) {
                payload = {
                    ...payload,
                    phone_number: formData?.phone_number?.split('-')[1]
                }
            }
            if (selectedRole == 'restaurant') {
                payload = {
                    ...payload,
                    first_name: contact_person
                }
            }
            if (payload?.mobile_number) {
                payload = {
                    ...payload,
                    mobile_number: formData?.mobile_number?.split('-')[1]
                }
            }
            payload = {
                ...payload,
                type: selectedRole == 'restaurant' ? 1 : "0"
            }
            if (formData?.categories?.length > 0) {
                const formattedCategories = categories.reduce((acc, value, index) => {
                    acc[`categories[${index}]`] = value;
                    return acc;
                }, {});
                payload = {
                    ...payload,
                    ...formattedCategories
                }
            }
            delete payload.categories
            if (selectedRole != 'concierge') {
                if (payload?.phone_number == payload?.mobile_number) {
                    setErrors((prev) => ({
                        ...prev,
                        'mobile_number': "Mobile number and Phone number must be different"
                    }));
                    toast.error("Mobile number and Phone number must be different", { toastId: "toast" })
                } else {
                    submitFuncntion(payload, success, fail)
                }
            } else {
                submitFuncntion(payload, success, fail)
            }


        }
    };

    // post signup data
    const submitFuncntion = (payload, success, fail) => {
        removeDataFromSessionStorage('signupData')
        setLoading(true)
        dispatch(registerUser(payload, success, fail))
    }


    return {
        formData, setFormData, navigate, handleChange, cuisines, showOTPScreen, setShowOTPScreen, selectedRole, handleSubmit, errors, step,
        setErrors, loading, addCuisine, setAddCuisine, getCuisineLoader, getCuisineData, getCategoriesLoader, categories, addBusinessName,
        setAddBusinessName, getBusinessNameLoader, businessData, setStep, businesses, roleValue, isSubCategoryRequired
    }
}

export default useRegister