import React from 'react'
import useProfile from './helper'
import DashboardLayout from 'Components/DashboardLayout'
import moment from 'moment'
import EditConciergeProfile from './EditProfile'
import Svgs from 'Assets/svgs'
import UpdatePassword from './ChangePassword'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import ConfirmationPopup from 'Components/ConfirmationPopup/ConfirmationPopup'

const ConciergeProfile = () => {
    const { user, isEditProfile, setIsEditProfile, activeTab, setActiveTab, deleteProfile, setDeleteProfile, deleteLoader,
        handleDeleteAccount, handleBack } = useProfile()
    return (
        <>
            <DashboardLayout active={'profile'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    {isEditProfile
                        ? <EditConciergeProfile setIsEdit={setIsEditProfile} />
                        : <>
                            <div className="space-y-4">
                                <Back title={'Profile'} onClick={handleBack} />
                                {!isEditProfile
                                    &&
                                    <div className="rounded-lg md:rounded-[10px] bg-darkGrey text-white p-5 md:p-8 flex sm:flex-row flex-col-reverse gap-5 justify-between items-start">
                                        <div className="flex items-center gap-5 sm:gap-8">
                                            <div className="relative h-fit w-fit min-w-36 min-h-36">
                                                {user?.profile_picture ?
                                                    <img className="rounded-full border-2 border-lightGrey object-cover w-36 h-36" src={user?.profile_picture} alt="user" />
                                                    : <Svgs.UserIcon2 width={'12.25rem'} height={'12.25rem'} />
                                                }
                                                <img className="absolute bottom-0 -right-5 h-12 w-12" src="/img/profile/star.svg" alt="star" />

                                            </div>
                                            <div>
                                                <h4 className="sm:text-2xl text-base font-bold mb-1">{user?.first_name ? user?.first_name : ""} {user?.last_name ? user?.last_name : ""}</h4>
                                                <p className="sm:text-base text-xs">{user?.email}</p>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <button
                                                onClick={() => setDeleteProfile(true)}
                                                className="flex items-center sm:w-auto w-full justify-between gap-2 cursor-pointer">
                                                <span>
                                                    <Svgs.DeleteIcon stroke={"#ef4444"} />
                                                </span>
                                                <p><span className="text-base font-semibold text-red-500">Delete Account</span></p>
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className="border-b border-lightGrey flex sm:flex-nowrap flex-wrap items-baseline gap-5 text-blueGrey">
                                    <p
                                        onClick={() => {
                                            setActiveTab('profile')
                                            setIsEditProfile(false)
                                        }}
                                        className={`${activeTab == 'profile' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                        Overview
                                    </p>
                                    <p onClick={() => setActiveTab('change_password')}
                                        className={`${activeTab == 'change_password' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                        Change Password
                                    </p>
                                    {/* <p onClick={() => setActiveTab('invite')}
                                        className={`${activeTab == 'invite' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>
                                        Invite Friend
                                    </p> */}
                                </div>
                            </div>

                            <div className="py-6">
                                {activeTab == 'profile' ?
                                    <>
                                        <div className="flex justify-between items-center">
                                            <h2 className="font-semibold text-2xl text-secondary">Profile Details</h2>
                                            <Button
                                                onClick={() => setIsEditProfile(true)}
                                                title={'Edit Profile'}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 py-10">
                                            <div className="grid grid-cols-2 gap-5">
                                                <div className="space-y-3 text-base text-blueGrey">
                                                    <p className='text-white'>First Name</p>
                                                    <p className='text-white'>Email Address</p>
                                                    <p className='text-white'>Mobile Number</p>
                                                    <p className='text-white'>Business Name</p>
                                                </div>

                                                <div className="space-y-3 text-base text-blueGrey font-semibold">
                                                    <p className='break-words break-all'>{user?.first_name} {user?.last_name} </p>
                                                    <p className='break-words break-all'>{user?.email}</p>
                                                    <p>{user?.mobile_number ? `+${user?.mobile_code} ${user?.mobile_number}` : "-----"}</p>
                                                    <p>{user?.business_name}</p>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2 gap-5">
                                                <div className="space-y-3 text-base text-blueGrey">
                                                    <p className='text-white'>Date of Birth</p>
                                                    <p className='text-white'>WhatsApp Number</p>
                                                </div>
                                                <div className="space-y-3 text-base font-semibold text-blueGrey">
                                                    <p>{user?.dob ? moment(user?.dob).format('DD-MM-YYYY') : ''}</p>
                                                    <p>{user?.wa_number ? `+${user?.wa_code} ${user?.wa_number}` : "-----"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : activeTab == 'change_password'
                                        ? <UpdatePassword setActiveTab={setActiveTab} />
                                        : ''
                                }
                            </div>
                        </>
                    }
                    {/* {!isEditProfile &&
                        <>
                            <p onClick={() => window.open(`${terms_condition_url}`, '_blank')}
                                className={`text-blueGrey cursor-pointer text-base pb-2 flex`}>
                                Terms & Conditions
                            </p>
                            <p onClick={() => window.open(`${privacy_policy_url}`, '_blank')}
                                className={`text-blueGrey cursor-pointer text-base pb-2 flex`}>
                                Privacy Policy
                            </p>
                        </>
                    } */}

                    <ConfirmationPopup
                        open={deleteProfile}
                        close={setDeleteProfile}
                        title={'For account deletion,we require your confirmation. Please select an option below to confirm'}
                        onYesClick={handleDeleteAccount}
                        yesLoader={deleteLoader}
                        removeHeader={true}
                    />
                </main>

            </DashboardLayout>

        </>
    )
}

export default ConciergeProfile