import { getRestaurantBookingAnalyticsReport } from "Adapters/APIs/Admin/analytics"
import { getDropdownConciergesAPI, getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

let initialFilterState = {
    dropdownValue: "",
    start_date: "",
    end_date: "",
    status: "",
    filter: ""
}
const useBookingAnalytics = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage("user_data")

    const state = useSelector(state => state)
    const dropdown_concierges = state?.adminDashboard?.dropdown_concierges
    const dropdown_restaurants = state?.adminDashboard?.dropdown_restaurants

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [activeTab, setActiveTab] = useState('concierges')
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            getBookingAnalytics()
        }
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            setActiveTab('concierges')
            getBookingAnalytics(true)
            setFilterData(initialFilterState)
        }
    }

    // get booking analytics data
    const getBookingAnalytics = (noFilter) => {
        let payload = {
            page: currentPage,
            per_page: 10
        }
        if (!noFilter) {
            let dropdownDataValue
            if (activeTab == 'concierges') {
                dropdownDataValue = {
                    concierges: filterData?.dropdownValue
                }
            } else {
                dropdownDataValue = {
                    restaurant: filterData?.dropdownValue
                }
            }
            payload = {
                ...payload,
                ...dropdownDataValue,
                ...filterData
            }
            delete payload.dropdownValue
        }
        setLoader(true)
        const success = (res) => {
            setData(res)
            setPaginationData(res?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getRestaurantBookingAnalyticsReport(access_token, payload, success, fail))
    }

    useEffect(() => {
        getBookingAnalytics()
    }, [currentPage])

    useEffect(() => {
        // get dropdown data for concierges and restaurants
        dispatch(getDropdownConciergesAPI())
        // dispatch(getDropdownRestaurantAPI())
    }, [])

    useEffect(() => {
        if (activeTab == "concierges") {
            setDropdownOptions(dropdown_concierges)
        } else {
            setDropdownOptions(dropdown_restaurants)
        }
    }, [activeTab, dropdown_concierges, dropdown_restaurants])

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    return {
        navigate, activeTab, setActiveTab, dropdownOptions, paginationData, currentPage, setCurrentPage, filterData, setFilterData,
        loader, data, addFilter, setAddFilter, initialFilterState, handleChangeFilter, filterUpdate, onClearFilter, onApplyFilter
    }
}

export default useBookingAnalytics