import Svgs from "Assets/svgs";
import React, { useEffect } from "react";
import Back from "./Back";
import { usePopupManager } from "Hooks/usePopupManager";

const Popup = ({ customPadding, popupColor, isBack, z, open, close, removeClose, heading, onBackClick, children, size, onclose, popupSize, noanimation, header, removeHeading, popupHeight, customSizeStyle, customHeight, FooterContent, notificationData }) => {
    const { openPopup, closePopup } = usePopupManager();

    useEffect(() => {
        if (open) {
            openPopup();
        } else {
            closePopup();
        }
        return () => {
            if (open) {
                closePopup();
            }
        };
    }, [open, openPopup, closePopup]);

    return (
        <>
            {open ? (
                <div
                    className={`fixed inset-0 ${popupSize ? popupSize : " h-[100vh] w-[100vw]"} ${z ? z : "z-[4444]"} flex justify-center items-center`}>
                    <div
                        className="bg-[#ffffff]/15 absolute inset-0 z-[1]"
                    ></div>
                    <div
                        className={`relative overflow-hidden shadow-2xl rounded-2xl ${popupColor ? popupColor : "bg-primary"} ${customSizeStyle ? customSizeStyle : size == "xs"
                            ? "lg:w-[35vw] xl:w-[30vw]"
                            : size == "sm"
                                ? "lg:w-[45vw] xl:w-[40vw]"
                                : size == "lg"
                                    ? "lg:w-[65vw]"
                                    : size == "xl"
                                        ? "lg:w-[90vw]"
                                        : "lg:w-[50vw]"
                            } w-[90vw] z-[2] ${!noanimation && 'slide-in-elliptic-top-fwd'} ${customHeight ? customHeight : ''}`}
                    >
                        {header && <div className={`sticky top-0 ${popupColor ? popupColor : "bg-primary"} z-[22]`}>
                            <div className={`flex justify-between items-center gap-4 ${!removeHeading ? "border-b border-lightGrey" : "!justify-end"} p-4`}>
                                {!removeHeading &&
                                    <div className="flex flex-1">
                                        {isBack ?
                                            <Back
                                                noPadding mainClass={'!flex'}
                                                onClick={onBackClick}
                                                fill={'var(--secondary-color)'}
                                                title={heading ? heading : "Add prop heading"} titleClassName={'normal-case font-semibold text-xl text-left text-white'} />
                                            : <h1 className={`normal-case font-semibold text-xl text-left text-white`}>
                                                {heading ? heading : "Add prop heading"}
                                            </h1>
                                        }
                                        <hr />
                                    </div>
                                }
                                {!removeClose &&
                                    <div className="md:pr-1.5 pr-1 md:pb-[3px] pb-1.5 rounded-bl-2xl flex  items-end justify-end">
                                        <div className="cursor-pointer" onClick={() => {
                                            const rootElement = document.getElementById('root');
                                            rootElement?.classList.remove('overflow-hidden')
                                            close(false);
                                            onclose();
                                        }}>
                                            <Svgs.CrossIcon2 />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>}
                        <div className={`${customPadding ? customPadding : ''} p-[1.2rem] overflow-y-auto overflow-x-hidden scroll-hidden ${popupHeight ? popupHeight : "lg:max-h-[75vh] max-h-[65vh]"}`}>
                            {notificationData ? (
                                <div>
                                    <h2 className="text-xl font-semibold">{notificationData.title}</h2>
                                    <p>{notificationData.body}</p>
                                </div>
                            ) : (children ? children : "Add prop children!")}
                        </div>
                        {FooterContent &&
                            <>
                                <div className='border border-b-[#E8E6EA] mt-2 !mx-0'></div>
                                {FooterContent}
                            </>
                        }
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

Popup.defaultProps = {
    close: () => { },
    open: true,
    header: true,
    children: <></>,
    onclose: () => { },
    removeClose: false,
    removeHeading: false
};

export default Popup;
