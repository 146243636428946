import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";
import { playSound } from "Utility/Howl";

// Hostel Concierge
const firebaseConfig = {
  apiKey: "AIzaSyBbnpNdOe6q65f4XO216ZsZbOE-b4FMIE4",
  authDomain: "bookingconcierge-421a9.firebaseapp.com",
  projectId: "bookingconcierge-421a9",
  storageBucket: "bookingconcierge-421a9.appspot.com",
  messagingSenderId: "975189347714",
  appId: "1:975189347714:web:e0ab88cc677e45432a139b",
  measurementId: "G-VN497MV0CY"
};
const vapidKey = 'BMJXkgborZY_4TkCcjQVbGE-kQgZZAQmiNDqGDX8dOLKnpls1Q582567WusN1Y6rvelosDXjlOgmvnc66afSp2I'

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Function to request for token
export const requestForToken = async (success) => {
  const permission = await Notification.requestPermission()
  try {
    console.log("api hit to get fcm token")
    const currentToken = await getToken(messaging, { vapidKey: vapidKey });
    if (currentToken) {
      success && success(currentToken)
      localStorage.setItem('fcm_token', JSON.stringify(currentToken));
      console.log("token generated successfully")
      // Perform any other necessary action with the token
    } else {
      // Show permission request UI
    }
  } catch (err) {
    // console.log(err, "er")
  }
};

// Function to listen for messages
export const onMessageListener = () => {
  return new Promise((resolve, reject) => {
    onMessage(messaging, (payload) => {
      if (payload) {
        resolve(payload);
      } else {
        reject('No payload received');
      }
    });
  });
};

// get default route data
function getUpdatedDefaultRoute() {
  const defaultRoute = localStorage.getItem("defaultRoute");

  if (defaultRoute) {
    // Remove leading slashes and split by '/'
    const segments = defaultRoute.replace(/^\/+/, '').split('/');
    // Check if the second segment is available (after '/dashboard')
    return segments[1] || ''; // Get the segment immediately after '/dashboard'
  }

  // Return a default value if no valid route is found
  return '';
}

onMessage(messaging, (payload) => {
  const user_data = JSON.parse(localStorage.getItem('user_data'))

  Cookies.set('getNotificationData', true)
  // Extract notification data
  const notificationData = payload.data;
  const notificationType = notificationData.type;
  const notificationBody = JSON.parse(notificationData?.body)
  const notificationRestaurantId = notificationBody?.notification?.restaurant_id;
  const completeData = notificationBody?.notification

  let clickActionUrl = '/';
  console.log(notificationType, ":notificationType", notificationBody, ":notificationBody")

  const updatedDefaultRoute = getUpdatedDefaultRoute();

  switch (notificationType) {
    case 'reservation_alert':
      playSound('reservationAlert');  // Play the success sound
      clickActionUrl = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`;
      break;
    case 'reservation_update_alert':
    case 'reservation_cancelled':
    case 'reservation_modified':
    case 'reservation_modified_confirmed':
    case 'reservation_confirmed':
    case 'reservation_no_show':
    case 'restaurant_request_alert':
    case 'bill_alert':
      clickActionUrl = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`;
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/booking`) && (!JSON.parse(sessionStorage.getItem('create_booking')) && updatedDefaultRoute != 'restaurant')) {
        window.location.href = `/${updatedDefaultRoute}/booking/?reservation=${completeData?.notification_data}`
      }
      break;

    case 'commission_paid_alert':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/commission`)) {
        window.location.href = `/${updatedDefaultRoute}/commission`
      }
      clickActionUrl = `/${updatedDefaultRoute}/commission`;
      break;

    case 'commission_confirmation_alert':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/payment-history`)) {
        window.location.href = `/${updatedDefaultRoute}/payment-history`
      }
      clickActionUrl = `/${updatedDefaultRoute}/payment-history`;
      break;

    case 'boost_request_approved':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/boosting-history`)) {
        window.location.href = `/${updatedDefaultRoute}/boosting-history/?slot_id=${completeData?.notification_data}`
      }
      clickActionUrl = `/${updatedDefaultRoute}/boosting-history/?slot_id=${completeData?.notification_data}`;
      break;

    case 'membership_confirmation_alert':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/invite-friend`)) {
        window.location.href = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`
      }
      clickActionUrl = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`;
      break;

    case 'withdraw_request_approved':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/invite-friend`)) {
        window.location.href = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`
      }
      clickActionUrl = `/${updatedDefaultRoute}/invite-friend/?active_tab=redeem-history`;
      break;

    case 'reward_alert':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/reward`)) {
        window.location.href = `/${updatedDefaultRoute}/reward`
      }
      clickActionUrl = `/${updatedDefaultRoute}/reward`;
      break;

    case 'wishlist_update_alert':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist`) || window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`)) {
        window.location.href = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`
      }
      clickActionUrl = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`;
      break;

    case 'wishlist_assigned_alert':
      if (window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist`) || window.location.pathname.includes(`/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`)) {
        window.location.href = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`
      }
      clickActionUrl = `/${updatedDefaultRoute}/wishlist/${completeData?.notification_data}`;
      break;

    case 'user_deactivate_alert':
      Cookies.set('logoutUser', true)
      break;

    case 'user_suspension_alert':
      break

    default:
      clickActionUrl = '/';
  }



  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: 'https://conciergebooking.tijarah.ae/er-logo.svg',
  };

  if (notificationType != "user_deactivate_alert") {
    // // Create a notification
    // const notification = new Notification(notificationTitle, notificationOptions);

    // // Add a click event listener
    // notification.onclick = (event) => {
    //   event.preventDefault(); // Prevent the browser from focusing the Notification's tab

    //   if (notificationRestaurantId && (user_data?.restaurant?.id !== notificationRestaurantId)) {
    //     // Call function to show the popup
    //     Cookies.set('notificationClickUrl', clickActionUrl);
    //     Cookies.set('notificationRestaurantId', notificationRestaurantId);
    //   } else {
    //     window.location.href = clickActionUrl;
    //   }
    // };
  }
});

// remove token
export const removeTokenFromFCM = async () => {
  const messaging = getMessaging();
  try {
    const token = await messaging.getToken();
    await deleteToken(messaging, token);
    console.log('FCM token deleted successfully.');
  } catch (error) {
    console.error('Error deleting FCM token:', error);
  }
};


//un register
export const unregisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.unregister().then(success => {
          if (success) {
            console.log('Service worker unregistered successfully.');
          } else {
            console.log('Service worker unregistration failed.');
          }
        });
      });
    }).catch(error => {
      console.error('Error getting service worker registrations:', error);
    });
  }
};


// Register the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      if (!localStorage.getItem('fcm_token') && JSON.parse(localStorage.getItem('access_token'))) {
        // Now request for FCM token
        requestForToken();
      }

      registration.onupdatefound = () => {
        const newWorker = registration.installing;
        newWorker.onstatechange = () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            // New service worker is installed and ready to activate
            console.log('New Service Worker update available.');
            // Optionally, show a notification to the user to refresh the page or automatically refresh:
            window.location.reload();
          }
        };
      };
    })
    .catch((err) => {
      console.error('Service Worker registration failed:', err);
    });

  // STORE COOKIES VALUE FROM BACKGROUND NOTIFICATIONS
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data && event.data.type === 'STORE_COOKIE') {
      const { key, value } = event.data;

      // Use js-cookie or vanilla JavaScript to set the cookie
      document.cookie = `${key}=${value}`; // Adjust the expiration date as needed
    }
  });

  // PLAY SOUND ON FROM BACKGROUND NOTIFICATION
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data && event.data.type === 'PLAY_SOUND') {
      const { scenario } = event.data;
      playSound(scenario);  // Call the playSound function from Howl.js
    }
  });
}