import Svgs from 'Assets/svgs'
import React from 'react'
import useRoleSelectionHelper from './helper'
import RoleSelectionCard from 'Components/Elements/RoleSelectionCard'
import Button from 'Components/Elements/Button'

const RoleSelection = () => {
    const { selectedRole, setSelectedRole, handleSelectedRole, navigate, loader, businessTypes, allBusinessTypes } = useRoleSelectionHelper()
    return (
        <section className="flex h-[100vh] w-full ">

            <div className="grid grid-cols-1 md:grid-cols-2 w-full ">
                <div className="flex-1 flex justify-center items-center h-full overflow-auto px-2">
                    <div className="max-w-fit w-full flex flex-col space-y-8">
                        <div className="flex justify-center">
                            <Svgs.AuthLogo width={'190'} height={'100'} />
                        </div>
                        <div className='h-[28rem] bg-darkGrey rounded-3xl p-8 text-center'>
                            <div className='flex flex-col space-y-2'>
                                <h2 className="text-3xl font-semibold pt-5 text-center text-white">Select Your Role</h2>
                                <p className='text-[#B7B7B7] text-center pt-1 pb-2 text-sm '>Are you a concierge or business owner?</p>
                            </div>
                            <div className="grid xs:grid-cols-2 grid-cols-1 gap-6 my-10">
                                {loader ?
                                    ([0, 1])?.map((itm, ind) => {
                                        return (
                                            <div className={`flex rounded-[16px] items-center justify-center cursor-pointer border-2 border-gray-500 bg-gray-500 h-40 px-20 animate-pulse`}></div>
                                        )
                                    })
                                    : allBusinessTypes?.map((itm, ind) => (
                                        <RoleSelectionCard
                                            key={ind}
                                            icon={itm?.type == 'restaurant' ?
                                                <Svgs.RestaurantIcon fill={'var(--secondary-color)'} />
                                                :
                                                <Svgs.ConciergeUserIcon fill={'var(--secondary-color)'} />
                                            }
                                            active={selectedRole == itm?.slug}
                                            name={itm?.name}
                                            onClick={() => {
                                                setSelectedRole(itm?.slug)
                                            }}
                                        />
                                    ))}
                            </div>
                            <div className=''>
                                <Button
                                    text={'Next'}
                                    className={'w-full'}
                                    onClick={handleSelectedRole}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center gap-2 text-sm pb-6">
                            <p className='text-white'>Already have an account?</p>
                            <span className='text-secondary cursor-pointer' onClick={() => navigate('/signup')}>Sign In</span>
                        </div>
                    </div>
                </div>
                <div
                    className="flex-1 w-full h-full bg-cover bg-no-repeat bg-center md:block hidden"
                    style={{ backgroundImage: "url('/img/auth/roleselect.png')" }}
                >
                    {/* <img className='h-full w-full' src="/img/auth/loginlayer.png" alt="" /> */}
                </div>
            </div>
        </section>
    )
}

export default RoleSelection