import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import FilePicker from 'Components/Elements/FilePicker'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import Textarea from 'Components/Elements/TextArea'
import { SupportRequestsCategories } from 'Constants/Data/Categories'
import React from 'react'
import useAddRequest from './helper'

const CreateRequest = ({ open, close, onSuccess }) => {
    const {
        onChangeHandler, request, loading, onSubmit, errors, handleClose
    } = useAddRequest(close, onSuccess)
    return (
        <>
            <Popup
                open={open}
                close={handleClose}
                heading={'Create Support Request'}
            >
                <div className="flex flex-col gap-5">
                    <LoginInput
                        error={errors?.title}
                        value={request?.title}
                        title={'Title'}
                        name={'title'}
                        onChange={onChangeHandler}
                        placeholder={'Enter title'}
                    />
                    <Dropdown
                        error={errors?.type}
                        value={request?.type}
                        name={'type'}
                        onChange={onChangeHandler}
                        title={'Category'}
                        placeholder={'Select Category'}
                        option={SupportRequestsCategories}
                    />
                    <Textarea
                        error={errors?.notes}
                        value={request?.notes}
                        title={'Notes'}
                        name={'notes'}
                        onChange={onChangeHandler}
                        rows={5}
                        placeholder={'Enter Notes'}
                    />
                    <FilePicker
                        error={errors?.image}
                        value={request?.image}
                        name={'image'}
                        onChange={onChangeHandler}
                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp,.WEBP"}
                    />
                    <Button
                        onClick={onSubmit}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            </Popup>
        </>
    )
}

export default CreateRequest