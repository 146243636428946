import { BASE_URL, booking_report_url, create_booking_url, get_all_booking_url, get_all_reservations_url, get_single_reservation, send_booking_email_followup_url, send_booking_email_url, update_booking_url } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { getAllBookings, getBookingAnalyticsAction } from "../../../Redux/Concierge/Bookings/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { buildQueryString } from "Constants/Functions/buildQueryString";

export const getUserAllBookings = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const quer = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_all_booking_url}${quer}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}
export const getAllReservations = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_all_reservations_url}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.promotions
            // dispatch(getAllPromotion(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}
export const createBookings = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${create_booking_url}`, payload, headers);
        if (response?.status == 200) {
            const data = response?.data?.reservation
            success && success(data)
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}
export const updateBookings = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_booking_url}/${payload?.number}`, payload, headers);
        if (response?.status == 200) {
            const data = response?.data?.promotions
            success && success()
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
            // dispatch(getAllPromotion(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}
export const sendBookingbyEmail = (access_token, payload, success, fail) => async (dispatch) => {
    let endUrl = payload?.sendEmailType == 'bookingByFollowUp' ? send_booking_email_followup_url : send_booking_email_url
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${endUrl}`, payload, headers);
        if (response?.status == 200) {
            const data = response?.data?.promotions
            success && success()
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
            // dispatch(getAllPromotion(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}
export const getConciergeBookingAnalytics = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${booking_report_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getBookingAnalyticsAction(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}