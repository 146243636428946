import React from 'react'
import useBookings, { initialBookingForm } from './helper';
import DashboardLayout from 'Components/DashboardLayout';
import CreateBooking from './CreateBookings';
import ConfirmBooking from './ConfirmBooking';
import Pagination from 'Components/Pagination';
import BookingTable from 'Utility/Tables/BookingTable';
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer';
import LoginInput from 'Components/Elements/LoginInput';
import Dropdown from 'Components/Elements/Dropdown';
import Button from 'Components/Elements/Button';
import Popup from 'Components/Elements/Popup';
import { BookingAnalyticsStatus } from 'Constants/Data/Categories';
import DateRangePicker from 'Components/Elements/ReactDateRange';
import Back from 'Components/Elements/Back';
import { BOOKING_STATUSES_WITHOUT_COMMISSION_PAID } from 'Constants/Data/BookingStatus';
import RadioBtn from 'Components/Elements/RadioBtn';
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer';
import DashboardCard from 'Components/ConceirgeCards/DashboardCard';
import Svgs from 'Assets/svgs';

const ConciergeBooking = () => {
    const {
        type, setType, loading, currentPage, bookingForm, restaurants, setCurrentPage, bookingPopup, setBookingPopup, allBookingData,
        filterData, setFilterData, startDate, setStartDate, endDate, setEndDate, bookingDetail, setBookingDetail, setBookingForm,
        pagination_data, setErrors, getBooking, sendMailPopup, setSendMailPopup, sendBookingByEmail, email, setEmail, emailLoading,
        errors, emailValidation, total_earning, singleLoading, sendEmailType, setSendEmailType, modificationLoading,
        requestModification
    } = useBookings()
    return (
        <>
            <DashboardLayout active={'booking'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="space-y-5">
                        <div className="flex justify-between items-center">
                            {type == "total" ?
                                loading ?
                                    <ConceirgeCardShimmer icon showViewDetail={false} customPadding={'!p-0 !py-2 min-w-40'} shimmerStyle={'!py-0'}/>
                                    :
                                    <DashboardCard
                                        className={'!p-0'}
                                        customPadding={'p-0'}
                                        icon1={<Svgs.TotalEarned width={22} height={22} />}
                                        title1={'Total earned since joining'}
                                        amount1={total_earning}
                                        showViewDetail={false}
                                    />
                                : <Back title={'Bookings'} /> 
                            }
                            <div className='w-fit'>
                                <Button
                                    onClick={() => {
                                        setErrors()
                                        setBookingPopup(!bookingPopup)
                                        setBookingForm(initialBookingForm)
                                    }}
                                >
                                    Create Booking
                                </Button>
                            </div>
                        </div>
                        <div className="border-b border-lightGrey flex items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setType('total')} className={`${type == 'total' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Total Bookings  &nbsp;
                            </p>
                            <p onClick={() => setType('upcomming')} className={`${type == 'upcomming' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Upcoming Bookings  &nbsp;
                            </p>

                        </div>
                    </div>
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="flex w-full md:flex-nowrap flex-wrap lg:gap-0 gap-3 items-end lg:items-center justify-end">
                                <div className="grid grid-cols-2 sm:grid-cols-3 items-center gap-3 w-full lg:w-fit">
                                    <Dropdown
                                        title={''}
                                        placeholder={'Select Status'}
                                        option={BookingAnalyticsStatus}
                                        onChange={(e) => setFilterData({
                                            ...filterData,
                                            status: e?.target?.value
                                        })}
                                        value={filterData?.status}
                                    />

                                    <Dropdown
                                        title={''}
                                        placeholder={'Select Business'}
                                        required={true}
                                        type="text"
                                        name="restaurant_id"
                                        onChange={(e) => setFilterData({
                                            ...filterData,
                                            restaurant_id: e?.target?.value
                                        })}
                                        value={filterData?.restaurant_id}
                                        id="restaurant_id"
                                        option={[
                                            { "label": "All", "value": "" },
                                            ...restaurants?.map(itm => {
                                                return {
                                                    value: itm?.id,
                                                    label: itm?.name
                                                }
                                            })]}
                                    />

                                    <DateRangePicker
                                        startDate={startDate}
                                        className="!max-w-none"
                                        endDate={endDate}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                    // maxDate={new Date()}
                                    />

                                </div>
                            </div>
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-table bg-primary">
                                    {loading
                                        ? <BookingTableShimer />
                                        : <>
                                            <BookingTable
                                                data={allBookingData}
                                                setErrors={setErrors}
                                                setBookingForm={setBookingForm}
                                                setBookingDetail={setBookingDetail}
                                                bookingDetail={bookingDetail}
                                                bookingStatusSteps={BOOKING_STATUSES_WITHOUT_COMMISSION_PAID}
                                                type={type}
                                            />
                                            {(type == 'total' && allBookingData?.length > 0) &&
                                                <Pagination
                                                    currentPage={currentPage}
                                                    pageSize={pagination_data?.per_page}
                                                    totalCount={pagination_data?.total_pages}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                    totalDataCount={pagination_data?.total}
                                                    currentPageCount={allBookingData?.length}
                                                    showing={pagination_data?.showing}
                                                />
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {bookingPopup
                        && <CreateBooking
                            data={bookingPopup}
                            close={setBookingPopup}
                            onSuccess={getBooking}
                        />
                    }
                    {bookingDetail &&
                        <ConfirmBooking
                            close={setBookingDetail}
                            onEditClick={setBookingPopup}
                            data={bookingForm}
                            sendMail={setSendMailPopup}
                            loading={singleLoading}
                            setErrors={setErrors}
                            errors={errors}
                            onReasonSubmit={requestModification}
                            btnLoading={modificationLoading}
                        />
                    }
                </main>

                {sendMailPopup &&
                    <Popup
                        open={sendMailPopup}
                        close={setSendMailPopup}
                        heading={'Send Email'}
                        customSizeStyle={'md:w-[40vw] xl:w-[30vw]'}
                    >
                        <div className="flex items-center gap-4 pb-5">
                            <div className={`border ${sendEmailType == 'bookingByEmail' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => { setSendEmailType('bookingByEmail') }}>
                                <RadioBtn name={'sendEmailType'} text={"Booking Email"} checked={sendEmailType == 'bookingByEmail'} id={'bookingByEmail'} />
                            </div>
                            <div className={`border ${sendEmailType == 'bookingByFollowUp' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => { setSendEmailType('bookingByFollowUp') }}>
                                <RadioBtn name={'sendEmailType'} text={"Follow Up"} checked={sendEmailType == 'bookingByFollowUp'} id={'bookingByFollowUp'} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-8">
                            <LoginInput
                                title={'Enter Email Address'}
                                placeholder={'Enter email address'}
                                name={'email'}
                                required={true}
                                error={errors?.email}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e?.target.value)
                                    emailValidation("email", e?.target?.value)
                                }}
                            />
                            <div className="flex gap-3">
                                <Button
                                    loading={emailLoading}
                                    onClick={() => {
                                        sendBookingByEmail(bookingForm?.id)
                                        // if (filterData?.distance || filterData?.category || filterData?.price_per_cover)
                                        // getRestaurants(1, 'clear')
                                        // else
                                        //     setFilterPopup(false)
                                        // setFilterData()
                                    }}
                                    text={'Submit'} className={'w-full'} />
                            </div>
                        </div>

                    </Popup>
                }

            </DashboardLayout>
        </>
    )
}

export default ConciergeBooking