import React from 'react'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import useMyWallet from './helper'
import DashboardCard from 'Components/ConceirgeCards/DashboardCard'
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'

const MyWallet = () => {
    const { navigate, loading, startDate, setStartDate, endDate, setEndDate, walletData } = useMyWallet()
    return (
        <>
            <DashboardLayout active={'wallet'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="pb-6">
                        <div className='flex flex-col gap-4' >
                            <div className='flex justify-between items-center gap-2 pb-4'>
                                <Back title={'My Wallet'} />
                                <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    maxDate={new Date()}
                                    className={'!w-full'}
                                />
                            </div>
                            {loading ?
                                <>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-5">
                                        <ConceirgeCardShimmer showViewDetail  />
                                        <ConceirgeCardShimmer showViewDetail />
                                        {/* <ConceirgeCardShimmer showViewDetail showAmount1={false} customPadding={"px-2 py-11"} /> */}
                                    </div>
                                    <div className="grid grid-cols-1">
                                        <ConceirgeCardShimmer showViewDetail showAmount1={false} />
                                    </div>
                                </> :
                                <>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-5">
                                        <DashboardCard
                                            title1={'Total booking commission'}
                                            amount1={walletData?.total_booking_commission}
                                            onAllClick={() => navigate('/concierge/profile/commission-analytics')}
                                            btnText={"View Detail"}
                                        />
                                        <DashboardCard
                                            title1={'Total referral commission'}
                                            amount1={walletData?.total_referral_commission}
                                            onAllClick={() => navigate('/concierge/invite-friend?active_tab=redeem-history')}
                                            btnText={"View Detail"}
                                        />
                                        {/* <DashboardCard
                                            title1={'Payment history'}
                                            onAllClick={() => navigate('/concierge/profile/transaction')}
                                            btnText={"View Detail"}
                                            // text1Class={"pb-2"}
                                        /> */}
                                    </div>
                                    <div className="grid grid-cols-1">
                                        <DashboardCard
                                            title1={'Payment history'}
                                            onAllClick={() => navigate('/concierge/profile/transaction')}
                                            btnText={"View Detail"}
                                            text1Class={"pb-2"}
                                        />
                                    </div>
                                </>}
                        </div>
                    </div>
                </main>
            </DashboardLayout>
        </>
    )
}

export default MyWallet