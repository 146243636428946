import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import React from 'react'

const DashboardCard = ({ icon1, icon2, icon3,iconWrapperStyle, cardHeading, amount1, title1, amount2, hideCurrency, cardRowStyle, headingClassName, title2, amount3, title3, btnText, onAllClick, className, customPadding, text1Class, showViewDetail, hideCurrency2 }) => {

    // const defaultIcon = <Svgs.BookingBagIcon />

    return (
        <div className={`relative bg-darkGrey p-4 rounded-[20px] cursor-pointer ${className}`} onClick={onAllClick && onAllClick}>
            {cardHeading && <h3 className="absolute left-5 top-4 text-white font-semibold">{cardHeading}</h3>}
            {showViewDetail && <div className='absolute right-0 top-0 bg-secondary py-2.5 px-4 rounded-bl-[20px] rounded-tr-[20px] text-sm font-bold '>
                {btnText ? btnText : "View Detail"}
            </div>}
            <div className={`flex justify-between w-full text-blueGrey gap-4 ${customPadding ? customPadding : "pt-10 px-4 py-6"} ${cardRowStyle ? cardRowStyle : ""}`}>
                <div className="rounded-[20px] p-4 bg-darkGrey space-y-3 flex-1">
                    <p className={`text-base font-semibold ${text1Class}`}>{title1}</p>
                    <div className="flex items-center gap-3">
                        {icon1 && <div className={`size-9 min-w-9 min-h-9 flex items-center rounded-[10px] justify-center bg-secondary ${iconWrapperStyle ? iconWrapperStyle : ''}`}>
                            {icon1}
                        </div>}
                        <p className="font-semibold">{amount1 || amount1 === 0 ? `${amount1} ` : ""} <span className="font-semibold text-secondary text-xs">{amount1 || amount1 === 0 ? ` ${hideCurrency ? "" : currency}` : ""} </span></p>
                    </div>
                </div>
                {(title2 || amount2) &&
                    <>
                        <div className="rounded-[20px] p-4 bg-darkGrey space-y-3 flex-1">
                            <p className={`text-base font-semibold`}>{title2}</p>
                            <div className="flex items-center gap-3">
                                {icon2 && <div className={`size-9 min-w-9 min-h-9 flex items-center rounded-[10px] justify-center bg-secondary ${iconWrapperStyle ? iconWrapperStyle : ''}`}>
                                    {icon2}
                                </div>}
                                <p className="font-semibold">{amount2 || amount2 === 0 ? `${amount2} ` : ""} <span className="font-semibold text-secondary text-xs">{amount2 || amount2 === 0 ? ` ${hideCurrency2 ? "" : currency}` : ""} </span></p>
                            </div>
                        </div>
                    </>
                }
                {(title3 || amount3) &&
                    <>
                        <div className="rounded-[20px] p-4 bg-darkGrey space-y-3 flex-1">
                            <p className={`text-base font-semibold`}>{title3}</p>
                            <div className="flex items-center gap-3">
                                {icon3 && <div className={`size-9 min-w-9 min-h-9 flex items-center rounded-[10px] justify-center bg-secondary ${iconWrapperStyle ? iconWrapperStyle : ''}`}>
                                    {icon3}
                                </div>}                                
                                <p className="font-semibold">{amount3 || amount1 === 0 ? `${amount3} ` : ""} <span className="font-semibold text-secondary text-xs">{amount3 || amount3 === 0 ? `${currency}` : ""} </span></p>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

DashboardCard.defaultProps = {
    showViewDetail: true
}

export default DashboardCard