import { getBusinessTypesData } from "Adapters/APIs/Authentication"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

const useRoleSelectionHelper = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // states
    const [selectedRole, setSelectedRole] = useState('')
    const [loader, setLoader] = useState(true)
    const [businessTypes, setBusinessTypes] = useState([])

    const allBusinessTypes = useMemo(() => {
        let business_types = businessTypes?.length > 0 ? businessTypes : [
            {
                "id": 1,
                "name": "Business",
                "slug": "business",
                "type": "restaurant",
                "status": 1
            },
            {
                "id": 2,
                "name": "Concierge",
                "slug": "concierge",
                "type": "concierge",
                "status": 1
            }
        ]
        return business_types
    }, [businessTypes])

    // handle submit selected role
    const handleSelectedRole = () => {
        navigate(`/signup/${selectedRole}`)
    }

    // get business types
    const getBusinessTypes = () => {
        const success = (data) => {
            setLoader(false)
            if (data?.data?.business_types?.length > 0) {
                setSelectedRole(data?.data?.business_types[0]?.slug)
                setBusinessTypes(data?.data?.business_types?.slice(0, 2))
            }
        }
        const fail = () => {
            setLoader(false)
        }
        setLoader(true)
        dispatch(getBusinessTypesData(success, fail))
    }

    useEffect(() => {
        getBusinessTypes()
    }, [])


    return {
        selectedRole, setSelectedRole, handleSelectedRole, navigate, loader, businessTypes, allBusinessTypes
    }
}

export default useRoleSelectionHelper