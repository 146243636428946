import Svgs from 'Assets/svgs'
import React from 'react'
import RestaurantSlider from '../RestaurantSlider'
import { currency } from 'Adapters/variables'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import DummyImage from "../../../../../Assets/images/dummtImg.png"
const RestaurantCard = (props) => {
    const {
        id, conciergeImage, isFeatured, showImages, images, index, name, phoneNumber, address, totalBookings, totalEarned, totalDues, active,
        viewEditProfileClick, viewBookingClick, hideStatus, onCardClick, bookBtn, hideInfo, showConciergeDetail, unpaidConciergeBookings,
        conciergeTotalCommission, conceirgeCommissionPercentage, deleted, navigate, menuBtn, onConciergeViewInvoiceClick, onConciergePayNowClick,
        customHeight, creditCardCheck
    } = props
    return (
        <>
            <div
                className={`${customHeight} bg-darkGrey rounded-[20px] xl:p-5 p-4 w-full cursor-pointer relative`}
                key={index}
                onClick={() => { onCardClick && onCardClick() }}
            >
                {isFeatured &&
                    <div className="absolute px-3 rounded-l-[10px] py-2 right-0 top-9 flex items-center gap-2 bg-black">
                        <Svgs.StarFill className={'!size-5'} />
                        <p className="text-white">Featured</p>
                    </div>
                }
                <div className="space-y-2">
                    {
                        images?.length > 0 ?
                            <Image
                                className={`w-full object-cover rounded-[20px] ${images?.length === 1 ? 'h-44' : 'xl:h-32 lg:h-48 h-52'}`}
                                src={images[0]?.url}
                                customLoaderClass={`w-full object-cover rounded-[20px] ${images?.length === 1 ? 'h-44' : 'xl:h-32 lg:h-48 h-52'}`}
                                customLoaderHeight={`${images?.length === 1 ? 'h-44' : 'xl:h-32 lg:h-48 h-52'}`}
                            />
                            :
                            <Image
                                className='h-44 w-full object-cover rounded-[20px]'
                                src={DummyImage}
                                alt="dummy"
                                customLoaderClass={'h-44 w-full object-cover rounded-[20px]'}
                                customLoaderHeight={'h-44'}
                            />
                    }

                    {(showImages && images.length > 1) &&
                        <RestaurantSlider data={images} hideRemove />
                    }
                </div>

                <div className='flex gap-2 items-center'>
                    {!showImages ?
                        conciergeImage ?
                            <div className="flex items-center justify-center h-[4rem] w-[4rem] overflow-hidden rounded-full bg-gray-200 ">
                                <Image src={conciergeImage} className="w-full h-full object-cover" />
                            </div>
                            :
                            <Svgs.UserIcon2 width={'56'} height={'56'} />
                        : ""
                    }
                    <div className="flex flex-1 flex-col space-y-2 mt-5">
                        <div className='flex justify-between items-center'>
                            <h1 className='font-semibold lg:text-2xl sm:text-xl text-lg text-white'>{name}</h1>
                            {hideStatus
                                ? menuBtn &&
                                <Button
                                    customPadding={'!p-2 gap-2'}
                                    onClick={() => { navigate && navigate(`/admin/restaurant-menu/${id}`) }}
                                    title={<>
                                        <Svgs.MenuIcon fill={'var(--primary-color)'} />
                                        Menu
                                    </>}
                                />
                                : deleted ?
                                    <div className='flex justify-between items-center gap-2 bg-[#C00E0E] px-3 py-2 rounded-lg'>
                                        <p className='text-xs text-white font-semibold'>Deleted</p>
                                    </div> :
                                    active ?
                                        <div className='flex justify-between items-center gap-2 bg-secondary px-3 py-2 rounded-lg'>
                                            {/* <Svgs.Tick /> */}
                                            <p className='text-xs text-primary font-semibold'>Active</p>
                                        </div> :
                                        <div className='flex justify-between items-center gap-2 bg-lightGrey px-3 py-2 rounded-lg'>
                                            <p className='text-xs text-primary font-semibold'>Inactive</p>
                                        </div>
                            }
                        </div>
                        <div className="flex gap-2 items-center text-sm text-blueGrey">
                            <span><Svgs.PhoneIcon stroke={'var(--secondary-color)'} /></span>
                            <span className="font-extralight">{phoneNumber}</span>
                        </div>
                        <div className="flex items-start gap-2 text-blueGrey mt-1 text-sm">
                            <span><Svgs.LocationIcon stroke={'var(--secondary-color)'} /></span>
                            <span className='extralight'>{address}</span>
                        </div>
                        <div className="flex items-start gap-2 text-blueGrey mt-1 text-sm">
                            <span><Svgs.CreditCardIcon width={'20'} height={'20'} stroke={'var(--secondary-color)'} /></span>
                            <span className='extralight'>{creditCardCheck ? "Credit card required for booking" : "Credit card not required for booking"}</span>
                        </div>
                    </div>
                </div>

                {!hideInfo &&
                    <div className="flex items-center justify-center mt-4">
                        <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                            <Svgs.CalendarGreen fill={'var(--secondary-color)'} />
                            <h1 className='font-medium text-sm text-blueGrey'>Total Bookings</h1>
                            <h1 className='font-semibold text-sm text-blueGrey'>{totalBookings}</h1>
                        </div>
                        <div className='border-[1.5px] mx-auto border-l border-lightGrey h-16'></div>
                        <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                            <Svgs.WalletLight stroke={'var(--secondary-color)'} />
                            <h1 className='font-medium text-sm text-blueGrey'>Total Earned</h1>
                            <h1 className='font-semibold text-sm text-blueGrey'>{(+totalEarned)?.toFixed(2)} {currency}</h1>
                        </div>
                        <div className='border-[1.5px] mx-auto border-l border-lightGrey h-16'></div>
                        <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                            <Svgs.DueLight stroke={'var(--secondary-color)'} />
                            <h1 className='font-medium text-sm text-blueGrey'>Total Due</h1>
                            <h1 className='font-semibold text-sm text-blueGrey'>{(+totalDues)?.toFixed(2)} {currency}</h1>
                        </div>
                    </div>
                }
                {bookBtn
                    ? <div className="flex items-center justify-end gap-4 mt-4">
                        {bookBtn}
                    </div>
                    : !showConciergeDetail &&
                    <div className="flex items-center justify-between gap-4 mt-4">
                        <Button text={'View & edit profile'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={viewEditProfileClick && viewEditProfileClick} />
                        <Button text={'View Booking'} className={'w-full'} onClick={viewBookingClick && viewBookingClick} />
                    </div>
                }
                {showConciergeDetail &&
                    <>
                        <div className='flex justify-between items-center gap-2 bg-lightGrey px-3 py-4 rounded-lg mt-2'>
                            <p className='text-blueGrey'>Unpaid Bookings</p>
                            <p className='font-semibold text-secondary'>{unpaidConciergeBookings ? unpaidConciergeBookings : 0}</p>
                        </div>
                        {unpaidConciergeBookings > 0 &&
                            <div className="flex items-center justify-between gap-4 my-4">
                                <Button text={'Pay Now'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={onConciergePayNowClick && onConciergePayNowClick} />
                                <Button text={'View Invoice'} className={'w-full'} onClick={onConciergeViewInvoiceClick && onConciergeViewInvoiceClick} />
                            </div>
                        }
                        <div className="flex items-center justify-center mt-4">
                            <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                                <h1 className='font-semibold text-sm text-blueGrey'>{conceirgeCommissionPercentage} %</h1>
                                <h1 className='font-medium text-sm text-blueGrey'>Commission</h1>
                            </div>
                            <div className='border-[1.5px] mx-auto border-l border-lightGrey h-16'></div>
                            <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                                <h1 className='font-semibold text-sm text-blueGrey'>{conciergeTotalCommission ? (+conciergeTotalCommission)?.toFixed(2) : 0} {currency}</h1>
                                <h1 className='font-medium text-sm text-blueGrey'>Total Commission</h1>
                            </div>
                        </div>
                    </>}
            </div>
        </>
    )
}

export default RestaurantCard