import React from 'react'
import Dropdown from 'Components/Elements/Dropdown'
import LoginInput from 'Components/Elements/LoginInput'
import Button from 'Components/Elements/Button'
import useCreateBookings from './helper'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import moment from 'moment'
import Back from 'Components/Elements/Back'
import Svgs from 'Assets/svgs'
import RestaurantFIlterPopup from 'Pages/Concierges/Restaurants/RestaurantFIlterPopup'
import Textarea from 'Components/Elements/TextArea'
import ReactDateTimePicker from 'Components/Elements/ReactDateTimePicker'
import PhoneInput from 'Components/Elements/PhoneInput'
import { currency } from 'Adapters/variables'
import { SORT_BY_FILTER } from 'Constants/Data/Categories'
import { convertObjectToArray } from 'Hooks/useObjectKeys'

const CreateBooking = ({ close, selectRestaurant, data, onSuccess }) => {
    const {
        errors, onCreateBooking, restaurants, onBookingChangeHandler, bookingForm, loading, onChangeHandler, filterPopup, setFilterPopup,
        filterData, setFilterData, getRestaurants, Cuisines, loader, appliedFilter, categories, removeDataFromSessionStorage
    } = useCreateBookings(close, selectRestaurant, data, onSuccess)

    return (
        <>
            <div
                className={`fixed inset-0 h-[100vh] w-[100vw] z-[999] flex justify-center items-center bg-[#ffffff]/15`}>

                <div className="h-full">
                    <div className="relative w-full max-h-full">
                        <div className="fixed h-screen top-0 right-0 max-w-lg bg-primary shadow ">
                            <div className="h-full w-full">
                                <div className="flex px-4 md:px-5">
                                    <Back title={data?.edit_booking ? 'Edit Booking' : 'Add Booking'} onClick={() => {
                                        close(false)
                                        removeDataFromSessionStorage('create_booking')
                                    }} />
                                </div>
                                {convertObjectToArray(appliedFilter)?.length > 0
                                    ? <div className="flex gap-5 my-3 w-[26rem] overflow-x-auto whitespace-nowrap px-4 py-2">
                                        {appliedFilter?.price_per_cover &&
                                            <div className="flex gap-1 text-white capitalize">
                                                <span className='font-semibold text-secondary'>Price per cover:</span>
                                                {appliedFilter?.price_per_cover} {currency}
                                            </div>
                                        }
                                        {appliedFilter?.radius &&
                                            <div className="flex gap-1 text-white capitalize">
                                                <span className='font-semibold text-secondary'>Radius:</span>
                                                {appliedFilter?.radius} KM
                                            </div>
                                        }
                                        {appliedFilter?.sort_by &&
                                            <div className="flex gap-1 text-white capitalize">
                                                <span className='font-semibold text-secondary'>Sort by:</span>
                                                {SORT_BY_FILTER?.find(itm => itm?.value == appliedFilter?.sort_by)?.label}
                                            </div>
                                        }
                                        {appliedFilter?.category &&
                                            <div className="flex gap-1 text-white capitalize">
                                                <span className='font-semibold text-secondary'>Category:</span>
                                                {categories?.find(itm => itm?.value == appliedFilter?.category)?.label}
                                            </div>
                                        }
                                        {appliedFilter?.cuisine_id &&
                                            <div className="flex gap-1 text-white capitalize">
                                                <span className='font-semibold text-secondary'>Sub Category:</span>
                                                {Cuisines?.find(itm => itm?.value == appliedFilter?.cuisine_id)?.label}
                                            </div>
                                        }
                                        {appliedFilter?.google_rating &&
                                            <div className="flex gap-1 text-white capitalize">
                                                <span className='font-semibold text-secondary'>Google Rating:</span>
                                                {appliedFilter?.google_rating}
                                            </div>
                                        }
                                        {appliedFilter?.tripadvisor_rating &&
                                            <div className="flex gap-1 text-white capitalize">
                                                <span className='font-semibold text-secondary'>Trip Advisor Rating:</span>
                                                {appliedFilter?.tripadvisor_rating}
                                            </div>
                                        }

                                    </div>
                                    : ""}
                                <div className="pt-2 pb-6 w-[26rem] gap-4 flex flex-col overflow-y-auto max-h-[90vh] px-4 md:px-5">
                                    <div className="flex w-full gap-4 items-end justify-between">
                                        {loader
                                            ? <div className='animate-pulse flex w-full flex-col gap-3' >
                                                <div className="bg-gray-500 w-3/5 flex items-center justify-center h-4 rounded-lg" />
                                                <div className="bg-gray-500 w-full flex items-center justify-center h-11 rounded-lg" />
                                            </div>
                                            :
                                            <div className='flex-1'>
                                                <Dropdown
                                                    title={'Business'}
                                                    placeholder={'Select Business'}
                                                    required={true}
                                                    disabled={selectRestaurant || data?.edit_booking}
                                                    type="text"
                                                    name="restaurant_id"
                                                    onChange={onBookingChangeHandler}
                                                    value={bookingForm?.restaurant_id}
                                                    error={errors?.restaurant_id}
                                                    id="restaurant_id"
                                                    option={
                                                        restaurants?.length > 0
                                                            ? restaurants?.map(itm => {
                                                                return {
                                                                    value: itm?.id,
                                                                    label: itm?.name
                                                                }
                                                            })
                                                            : [
                                                                {
                                                                    value: '',
                                                                    label: 'No business found'
                                                                }
                                                            ]
                                                    }
                                                />
                                            </div>
                                        }
                                        {(!selectRestaurant && !data?.edit_booking) &&
                                            <Button
                                                onClick={() => setFilterPopup(true)}
                                                customPadding={'py-[13px] px-5'}
                                                title={<Svgs.FilterIcon fill={'var(--primary-color)'} />}
                                            />
                                        }
                                    </div>
                                    <LoginInput
                                        title={'Guest Full Name'}
                                        required={true}
                                        type="text"
                                        name="guest_name"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.guest_name}
                                        error={errors?.guest_name}
                                        id="guest_fullName"
                                        placeholder="Enter Guest Full Name"
                                        onlyAlphabetsAllow
                                    />
                                    <LoginInput
                                        title={'Total Guest'}
                                        required={true}
                                        type="number"
                                        name="total_pax"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.total_pax}
                                        error={errors?.total_pax}
                                        id="pax"
                                        placeholder="Enter Total Guest"
                                        onlyNumericAllowed
                                        firstLetterGreaterThanZero
                                    />
                                    <ReactDateTimePicker
                                        title={'Date'}
                                        required={true}
                                        type="date"
                                        name="date"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.date}
                                        error={errors?.date}
                                        min={moment().subtract(1, 'day').toDate()}
                                        // min={new Date()}
                                        isClearable={true}
                                        time={bookingForm?.time}
                                    />
                                    {/* <LoginInput
                                        title={'Date'}
                                        required={true}
                                        type="date"
                                        name="date"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.date}
                                        error={errors?.date}
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    <LoginInput
                                        title={'Time'}
                                        required={true}
                                        type="time"
                                        name="time"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.time}
                                        error={errors?.time}
                                    /> */}
                                    <PhoneInput
                                        required={false}
                                        title={'Guest Phone number'}
                                        placeholder={'Enter guest phone number'}
                                        onChange={onBookingChangeHandler}
                                        name={'guest_phone_number'}
                                        value={bookingForm?.guest_phone_number}
                                        error={errors?.guest_phone_number}
                                    />
                                    <LoginInput
                                        required={false}
                                        title={'Celebrating any occasion'}
                                        name="occasion"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.occasion}
                                        error={errors?.occasion}
                                        charactersMaxLength={191}
                                        placeholder="Enter celebrating any occasion"
                                    />
                                    <LoginInput
                                        required={false}
                                        title={'Allergies'}
                                        charactersMaxLength={191}
                                        name="allergies"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.allergies}
                                        error={errors?.allergies}
                                        placeholder="Enter allergies"
                                        specialCharacterNotAllowed

                                    />
                                    <Textarea
                                        required={false}
                                        title={'Special Notes'}
                                        name="special_note"
                                        onChange={onBookingChangeHandler}
                                        value={bookingForm?.special_note}
                                        error={errors?.special_note}
                                        placeholder="Write Message"
                                    />
                                    <Button onClick={onCreateBooking}>
                                        {loading
                                            ? <div className="flex">
                                                <PreloaderSm />
                                            </div>
                                            : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RestaurantFIlterPopup
                open={filterPopup}
                close={setFilterPopup}
                Cuisines={Cuisines}
                categories={categories}
                onChange={onChangeHandler}
                filterData={filterData}
                setFilterData={setFilterData}
                onClearFilter={() => {
                    getRestaurants(1, 'clear')
                }}
                onSubmit={() => {
                    getRestaurants(1)
                }}
            />

        </>
    )
}

export default CreateBooking
