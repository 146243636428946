import { UpdateFCMToken } from "Adapters/APIs/Authentication";
import { requestForToken } from "firebase.config";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const NotificationFCMHandler = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const fcm_token = getDataFromLocalStorage('fcm_token')

    useEffect(() => {
        // Check if notification permission is already stored in localStorage
        const notificationPermission = localStorage.getItem('notificationPermission');

        // If the user hasn't allowed or denied notifications, ask for permission
        if (!notificationPermission || notificationPermission === 'default') {
            askForNotificationPermission();
        }
    }, []);

    // Add FCM Token In Database
    const add_FCM_Tokens = (token) => {
        dispatch(UpdateFCMToken(access_token, {
            fcm_token: token,
            is_web: true
        }))
    }

    // Get FCM Token
    const requestFireBaseToken = async () => {
        const success = (token) => {
            storeDataInLocalStorage('fcm_token', token)
            add_FCM_Tokens(token, access_token)
        }
        requestForToken(success)
    }

    const askForNotificationPermission = () => {
        if (Notification.permission === "default") {
            Notification.requestPermission().then((permission) => {
                // Store the permission in localStorage
                localStorage.setItem('notificationPermission', permission);

                if (permission === "granted" && access_token && !fcm_token) {
                    // Generate FCM token or any other actions you need to perform
                    requestFireBaseToken();
                }
            });
        }
    };

    return null;
};

export default NotificationFCMHandler;
