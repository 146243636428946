import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import React from 'react'

const RestaurantFilterCircleCard = ({ icon, text, onClick, key, loading }) => {
    return (
        loading ?
            <div className='mb-2 rounded-[10px] flex flex-col gap-2 justify-center bg-darkGrey items-center cursor-pointer animate-pulse  px-[14px] py-[10px]' key={key}>
                <div className='px-4 py-2 bg-gray-500 mb-2 rounded-[10px] flex flex-col gap-2 items-center justify-between'>
                    <div className="w-7 min-w-6 min-h-6"></div>
                </div>                
                <div className='text-sm bg-gray-500 px-1 h-3 rounded-md w-full'/>
                <div className='text-sm bg-gray-500 px-1 h-3 rounded-md mx-auto w-1/2'/>
                <div className='text-sm bg-gray-500 size-6 mt-2 rounded-full'/>
            </div>
            :
            <div className='bg-darkGrey group mb-2 rounded-[10px] flex flex-col gap-2 items-center justify-between cursor-pointer w-[85px] text-center px-[14px] py-[10px]' key={key} onClick={onClick && onClick}>
                <div className=" flex flex-col gap-2 items-center">
                    <div className='group-hover:bg-[--secondary-color] rounded-md bg-[--lightGrey-color] px-4 py-2'>
                        <Image
                            src={icon}
                            className={'w-7 min-w-6 min-h-6 object-cover filter brightness-0 invert group-hover:invert-0 group-hover:saturate-[999%] group-hover:brightness-50 group-hover:hue-rotate-[10deg]'}
                            customLoaderHeight={" w-full h-full"}
                        />
                    </div>
                    <h4 className='text-sm text-blueGrey px-1 line-clamp-2'>{text}</h4>
                </div>
                <div className="size-6 rounded-full flex items-center justify-center bg-lightGrey group-hover:!bg-[--secondary-color] -rotate-90">
                    <Svgs.ArrowDown width={'10'} height={'20'} fill={"white"} />
                </div>
            </div>
    )
}

export default RestaurantFilterCircleCard