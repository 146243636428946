import Svgs from "Assets/svgs"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RestaurantSidebarRoutes = (active, user_data) => {
    const resturentDashBoard = [
        {
            url: '/restaurant/dashboard',
            heading: 'Dashboard',
            active: active == 'Dashboard',
            icon: <Svgs.DashbaordIcon />
        },
        {
            url: '/restaurant/booking',
            heading: 'Bookings',
            active: active == 'booking',
            icon: <Svgs.BookingIcon fill={active == "booking" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        // {
        //     url: '/restaurant/promotion',
        //     heading: 'Promotions',
        //     active: active == 'promotion',
        //     icon: <Svgs.PromotionIcon fill={active == 'promotion' ? 'var(--primary-color)' : 'var(--blueGrey-color)'} />
        // },
        {
            url: '/restaurant/menu',
            heading: 'Menu',
            active: active == 'menu',
            icon: <Svgs.MenuIcon fill={active == 'menu' ? "var(--primary-color)" : 'var(--blueGrey-color)'} height={20} width={20} />
        },
        {
            url: '/restaurant/review',
            heading: 'Reviews',
            active: active == 'review',
            icon: <Svgs.ReviewIcon fill={active == 'review' ? "var(--primary-color)" : 'var(--blueGrey-color)'} height={20} width={20} />
        },
        {
            url: '/restaurant/payment',
            heading: 'Booking Fees',
            active: active == 'payment',
            icon: <Svgs.WalletIcon fill={active == "payment" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/restaurant/chat',
            heading: 'Chat',
            active: active == 'chat',
            icon: <Svgs.ChatIcon />
        },
        {
            url: '/restaurant/support-request',
            heading: 'Support Request',
            active: active == 'SupportRequest',
            icon: <Svgs.WishlistIcon fill={active == "SupportRequest" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/restaurant/booking-analytics',
            heading: 'Booking Analytics',
            active: active == 'analytics',
            icon: <FontAwesomeIcon icon={"fa fa-line-chart"} style={{ height: '1.5rem', width: '1.3rem', color: active == 'analytics' ? "var(--primary-color)" : "var(--blueGrey-color)" }} />
        },
        {
            url: '/restaurant/financials',
            heading: 'Financials',
            active: active == 'financials',
            icon: <Svgs.Financials fill={active == "financials" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/restaurant/invite-friend',
            heading: 'Invite Friend',
            active: active == 'InviteFriend',
            icon: <Svgs.InviteFriend fill={active == "InviteFriend" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />,
            isChild: !user_data?.is_parent
        },
        {
            url: '/restaurant/subscription-payments',
            heading: 'Subscription Payments',
            active: active == 'subscription-payments',
            icon: <Svgs.SubscriptionIcon fill={active == "subscription-payments" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/restaurant/invoice-report',
            heading: 'Invoice Report',
            active: active == 'invoice-report',
            icon: <Svgs.InvoiceReportIcon fill={active == "invoice-report" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/restaurant/profile-boosting',
            heading: 'Profile Boosting',
            active: active == 'profile-boosting',
            icon: <Svgs.ProfileBoosting fill={active == "profile-boosting" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/restaurant/boosting-history',
            heading: 'Boosting History',
            active: active == 'boosting-history',
            icon: <Svgs.ProfileBoostingHistory fill={active == "boosting-history" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/restaurant/business',
            heading: 'Add Business',
            active: active == 'business',
            icon: <Svgs.AddBusinesIcon fill={active == "business" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />,
            isChild: !user_data?.is_parent
        },
        {
            url: '/restaurant/profile',
            heading: 'Profile',
            active: active == 'profile',
            icon: <Svgs.ProfileIcon />
        },

    ]
    return resturentDashBoard
}

export default RestaurantSidebarRoutes