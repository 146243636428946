import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import ProgressBar from 'Components/Elements/ProgressBar'
import React from 'react'

const EarningHistoryCard = ({ name, doneValue, earning, type }) => {
    return (
        <div className='bg-darkGrey rounded-lg relative'>
            <div className='flex gap-2 justify-between items-center py-4 px-4'>
                <div className='flex gap-2 justify-between items-center'>
                    <Svgs.UserIcon2 width={'50'} height={'50'} />
                    <div className='flex flex-col space-y-1 pl-2'>
                        <p className='text-white'>{name}</p>
                        <ProgressBar total={10} doneValue={doneValue} />
                    </div>
                </div>
            </div>
            <div className='absolute right-0 bottom-0 px-2 py-0.5 text-sm rounded-tl-lg rounded-br-lg bg-secondary'>
                {type}
            </div>
            <div className='absolute right-0 top-2.5 px-2 py-0.5 text-sm rounded-l-lg text-white bg-customGreen'>
                {earning} {currency}
            </div>
        </div>
    )
}

export default EarningHistoryCard