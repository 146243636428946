import { useDispatch } from "react-redux";
import { generateTestNotifications } from "Adapters/APIs/Authentication";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
// ------- Auth ------ //
import Login from "../OnBaording/Login";
import Signup from "../OnBaording/Signup";
import ForgotPassword from "OnBaording/ForgotPassword";
import OTPScreen from "OnBaording/OTPVerification/OTPScreen";
import RoleSelection from "OnBaording/RoleSelection";

// ------- Admin ------ //
import MainDashBoardAdmin from "../Pages/Admin/MainDashbaord";
import AdminStatisticsDetail from "Pages/Admin/MainDashbaord/StatisticsDetail";
import UnapprovedConciergeRestaurant from "Pages/Admin/MainDashbaord/UnapprovedConciergeRestaurant";
import AdminPendingRecievables from "Pages/Admin/MainDashbaord/PendingRecievables";
import AdminPendingPayables from "Pages/Admin/MainDashbaord/PendingPayables";
import AdminUnapprovedRestaurant from "Pages/Admin/MainDashbaord/UnapprovedConciergeRestaurant/Restaurant";
import EditRestaurantByAdmin from "Pages/Admin/MainDashbaord/UnapprovedConciergeRestaurant/Restaurant/EditRestaurantByAdmin";
import AdminUnapprovedConcierge from "Pages/Admin/MainDashbaord/UnapprovedConciergeRestaurant/Concierge";
import EditConciergeByAdmin from "Pages/Admin/MainDashbaord/UnapprovedConciergeRestaurant/Concierge/EditConciergeByAdmin";
import AdminRestaurants from "Pages/Admin/Restaurants";
import AdminConcierges from "Pages/Admin/Concierges";
import AdminSingleRestaurant from "Pages/Admin/Restaurants/SingleRestaurant";
import SingleRestaurantFeedback from "Pages/Admin/Restaurants/SingleRestaurant/Feedback";
import SingleRestaurantMenu from "Pages/Admin/Restaurants/SingleRestaurant/Menu";
import SingleRestaurantBookings from "Pages/Admin/Restaurants/SingleRestaurant/Bookings";
import AdminWishlist from "Pages/Admin/Wishlist";
import AdminSingleConcierge from "Pages/Admin/Concierges/SingleConcierge";
import SingleConciergeBookings from "Pages/Admin/Concierges/SingleConcierge/Bookings";
import AdminRewards from "Pages/Admin/Rewards";
import AdminTransactionPayments from "Pages/Admin/Transaction&Payments";
import AdminPromotions from "Pages/Admin/Promotions";
import AdminPromotionRequest from "Pages/Admin/PromotionRequest";
import AdminFinance from "Pages/Admin/Finance";
import AdminStaff from "Pages/Admin/Staff";
import AdminSupportRequests from "Pages/Admin/SupportRequests";
import AdminAnalyticsDashboard from "Pages/Admin/Analytics/AnalyticsDashboard";
import AdminBookingAnalytics from "Pages/Admin/Analytics/BookingAnalytics";
import AdminConciergeRestaurantAnalytics from "Pages/Admin/Analytics/ConciergeRestaurantAnalytics";
import AdminSpecificConciergeRestaurantAnalytics from "Pages/Admin/Analytics/SpecificConciergeRestaurantAnalytics";

// ======================== Concierge ========================= \\
import ConceirgeDashbaord from "../Pages/Concierges/MainDashbaord";
import AllPromotions from "Pages/Concierges/Promotions";
import ConciergeRestaurant from "Pages/Concierges/Restaurants";
import ConciergeBooking from "Pages/Concierges/Bookings";
import ConceirgeChat from "Pages/Concierges/Chat";
import ConciergeCommission from "Pages/Concierges/Commission";
import Transaction from "Pages/Concierges/Transaction";
import Rewards from "../Pages/Concierges/Rewards";
import ConciergeProfile from "Pages/Concierges/Profile";
import SupportRequests from "Pages/Concierges/SupportRequests";
import BookingAnalytics from "Pages/Concierges/BookingAnalytics";
import WishlistConcierge from "Pages/Concierges/Wishlist";
import ConciergeProfileCommissionAnalytics from "Pages/Concierges/MyWallet/Commission";
import ConciergeProfileTransaction from "Pages/Concierges/MyWallet/Transaction";
import AnnounceRewardDetail from "Pages/Concierges/Rewards/AnnounceRewardDetail";
import WishListDetail from "Pages/Concierges/Wishlist/WishlistDetail";
import MyWallet from "Pages/Concierges/MyWallet";
import InviteFriend from "Pages/Concierges/InviteFriend";


// ======================== Restaurant ========================= \\
import RestaurantDashbaord from "Pages/Restaurants/MainDashbaord";
import RestaurantBooking from "Pages/Restaurants/Bookings";
import RestaurantProfile from "Pages/Restaurants/Profile";
// import RestaurantAllPromotions from "Pages/Restaurants/Promotions";
import RestaurantChat from "Pages/Restaurants/Chat";
import RestaurantSupportRequests from "Pages/Restaurants/SupportRequests";
import RestaurantBookingAnalytics from "Pages/Restaurants/BookingAnalytics";
import RestaurantMenu from "Pages/Restaurants/Menu";
import RestaurantFeedback from "Pages/Restaurants/Feedback";
import RestaurantPackagesPromotions from "Pages/Restaurants/Packages";
import RestaurantPayments from "Pages/Restaurants/Payment";
import PaymentHistory from "Pages/Restaurants/Payment/PaymentHistory";
import SubscriptionPayment from "Pages/Restaurants/SubscriptionPayments";
import RestaurantFinancials from "Pages/Restaurants/Financials";
import BoostPayments from "Pages/Restaurants/Financials/BoostPayments";
import RestaurantDetail from "Pages/Concierges/Restaurants/RestaurantDetail";
import RestaurantInvoiceReport from "Pages/Restaurants/InvoiceReport";
import PendingPayments from "Pages/Restaurants/SubscriptionPayments/PendingPayments";
import RestaurantAllBusiness from "Pages/Restaurants/Businesses";
import CreateUpdateBusiness from "Pages/Restaurants/Businesses/AddBusiness";
import ProfileBoosting from "Pages/Restaurants/ProfileBoosting";
import ProfileBoostingHistory from "Pages/Restaurants/BoostingHistory";

const useRouteHelper = () => {
    const dispatch = useDispatch()

    // Check if user data exists in localStorage
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')
    const defaultRoute = getDataFromLocalStorage('defaultRoute')


    // api to generate test notifications
    const generateTestNotification = () => {
        const success = () => { }
        const fail = () => { }
        dispatch(generateTestNotifications(access_token, user_data?.id))
    }


    const conciergeRoutes = [
        {
            path: "/concierge/dashboard",
            element: <ConceirgeDashbaord />,
        },
        {
            path: "/concierge/promotions",
            element: <AllPromotions />,
        },
        {
            path: "/concierge/restaurant",
            element: <ConciergeRestaurant />,
        },
        {
            path: '/concierge/restaurant/:restuarant_id',
            element: <RestaurantDetail />,
        },
        {
            path: '/concierge/restaurant-menu/:restuarant_id',
            element: <SingleRestaurantMenu active={'Dashboard'} />,
        },
        {
            path: '/concierge/restaurant-feedback/:restuarant_id',
            element: <SingleRestaurantFeedback active={"Dashboard"} />,
        },
        {
            path: "/concierge/booking",
            element: <ConciergeBooking />,
        },
        {
            path: "/concierge/commission",
            element: <ConciergeCommission active={'Dashboard'} />,
        },
        {
            path: "/concierge/reward",
            element: <Rewards />,
        },
        {
            path: "/concierge/announce-reward",
            element: <AnnounceRewardDetail />,
        },
        {
            path: "/concierge/chat",
            element: <ConceirgeChat />,
        },
        {
            path: "/concierge/transaction",
            element: <Transaction />,
        },
        {
            path: "/concierge/support-request",
            element: <SupportRequests />,
        },
        {
            path: "/concierge/profile",
            element: <ConciergeProfile />,
        },
        {
            path: "/concierge/booking-analytics",
            element: <BookingAnalytics />,
        },
        {
            path: "/concierge/wishlist",
            element: <WishlistConcierge />,
        },
        {
            path: "/concierge/wishlist/:id",
            element: <WishListDetail />,
        },
        {
            path: "/concierge/my-wallet",
            element: <MyWallet />,
        },
        {
            path: "/concierge/profile/commission-analytics",
            element: <ConciergeProfileCommissionAnalytics />,
        },
        {
            path: "/concierge/profile/transaction",
            element: <ConciergeProfileTransaction />,
        },
        {
            path: "/concierge/invite-friend",
            element: <InviteFriend />,
        },
    ]

    const restaurantRoutes = [
        {
            path: '/restaurant/dashboard',
            element: <RestaurantDashbaord />,
        },
        {
            path: '/restaurant/booking',
            element: <RestaurantBooking />,
        },
        // {
        //     path: '/restaurant/promotion',
        //     element: <RestaurantAllPromotions isMain={true} />,
        // },
        {
            path: '/restaurant/chat',
            element: <RestaurantChat />,
        },
        {
            path: '/restaurant/menu',
            element: <RestaurantMenu />,
        },
        {
            path: '/restaurant/payment',
            element: <RestaurantPayments />,
        },
        {
            path: '/restaurant/payment-history',
            element: <PaymentHistory />,
        },
        {
            path: '/restaurant/subscription-payments',
            element: <SubscriptionPayment />,
        },
        {
            path: '/restaurant/subscription-history',
            element: <PaymentHistory
                title="Subscription Payments"
                subscription={true}
            />,
        },
        {
            path: '/restaurant/subscription-pending-history',
            element: <PendingPayments />,
        },
        {
            path: '/restaurant/review',
            element: <RestaurantFeedback />,
        },
        {
            path: '/restaurant/financials',
            element: <RestaurantFinancials />,
        },
        {
            path: '/restaurant/boost-business',
            element: <RestaurantPackagesPromotions />,
        },
        {
            path: '/restaurant/support-request',
            element: <RestaurantSupportRequests />,
        },
        {
            path: '/restaurant/booking-analytics',
            element: <RestaurantBookingAnalytics />,
        },
        {
            path: '/restaurant/invoice-report',
            element: <RestaurantInvoiceReport />,
        },
        {
            path: '/restaurant/profile',
            element: <RestaurantProfile />,
        },
        {
            path: '/restaurant/boost-payments',
            element: <BoostPayments />,
        },
        {
            path: "/restaurant/invite-friend",
            element: <InviteFriend />,
        },
        {
            path: '/restaurant/business',
            element: <RestaurantAllBusiness />
        },
        {
            path: '/restaurant/create-business',
            element: <CreateUpdateBusiness />
        },
        {
            path: '/restaurant/update-business',
            element: <CreateUpdateBusiness />
        },
        {
            path: '/restaurant/profile-boosting',
            element: <ProfileBoosting />
        },
        {
            path: '/restaurant/boosting-history',
            element: <ProfileBoostingHistory />
        }
    ]

    const adminRoutes = [
        {
            path: '/admin/dashboard',
            element: <MainDashBoardAdmin />,
        },
        {
            path: "/admin/concierge-statistics",
            element: <AdminStatisticsDetail />,
        },
        {
            path: "/admin/restaurant-statistics",
            element: <AdminStatisticsDetail />,
        },
        {
            path: "/admin/unapproved-concierges",
            element: <UnapprovedConciergeRestaurant />,
        },
        {
            path: "/admin/unapproved-restaurants",
            element: <UnapprovedConciergeRestaurant />,
        },
        {
            path: "/admin/unapproved-concierge/:user_id",
            element: <AdminUnapprovedConcierge />,
        },
        {
            path: "/admin/edit-concierge/:user_id",
            element: <EditConciergeByAdmin />,
        },
        {
            path: "/admin/unapproved-restaurant/:restuarant_id",
            element: <AdminUnapprovedRestaurant />,
        },
        {
            path: "/admin/edit-restaurant/:restuarant_id",
            element: <EditRestaurantByAdmin />,
        },
        {
            path: "/admin/pending-recievables",
            element: <AdminPendingRecievables />,
        },
        {
            path: "/admin/pending-payables",
            element: <AdminPendingPayables />,
        },
        {
            path: '/admin/restaurants',
            element: <AdminRestaurants />,
        },
        {
            path: '/admin/concierges',
            element: <AdminConcierges />,
        },
        {
            path: '/admin/restaurant/:restuarant_id',
            element: <AdminSingleRestaurant />,
        },
        {
            path: '/admin/restaurant-feedback/:restuarant_id',
            element: <SingleRestaurantFeedback />,
        },
        {
            path: '/admin/restaurant-menu/:restuarant_id',
            element: <SingleRestaurantMenu />,
        },
        {
            path: '/admin/restaurant-bookings/:restuarant_id',
            element: <SingleRestaurantBookings />,
        },
        {
            path: '/admin/concierge/:user_id',
            element: <AdminSingleConcierge />,
        },
        {
            path: '/admin/concierge-bookings/:user_id',
            element: <SingleConciergeBookings />,
        },
        {
            path: '/admin/wishlist',
            element: <AdminWishlist />,
        },
        {
            path: '/admin/rewards',
            element: <AdminRewards />,
        },
        {
            path: "/admin/transactions-payments",
            element: <AdminTransactionPayments />,
        },
        {
            path: "/admin/promotions",
            element: <AdminPromotions />,
        },
        {
            path: "/admin/promotion-request",
            element: <AdminPromotionRequest />,
        },
        {
            path: "/admin/finance",
            element: <AdminFinance />,
        },
        {
            path: "/admin/staff",
            element: <AdminStaff />,
        },
        {
            path: "/admin/analytics",
            element: <AdminAnalyticsDashboard />,
        },
        {
            path: "/admin/analytics/booking-analytics",
            element: <AdminBookingAnalytics />,
        },
        {
            path: "/admin/analytics/concierges-analytics",
            element: <AdminConciergeRestaurantAnalytics />,
        },
        {
            path: "/admin/analytics/restaurants-analytics",
            element: <AdminConciergeRestaurantAnalytics />,
        },
        {
            path: "/admin/analytics/specific-concierge-analytics",
            element: <AdminSpecificConciergeRestaurantAnalytics />,
        },
        {
            path: "/admin/analytics/specific-restaurant-analytics",
            element: <AdminSpecificConciergeRestaurantAnalytics />,
        },
        {
            path: "/admin/support-request",
            element: <AdminSupportRequests />,
        },
    ]

    const authRoutes = [
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/role-selection",
            element: <RoleSelection />,
        },
        {
            path: "/signup/:role",
            element: <Signup />,
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword />,
        },
        {
            path: "/otp-verification",
            element: <OTPScreen />,
        }
    ];
    return {
        conciergeRoutes, authRoutes, restaurantRoutes, adminRoutes, access_token, defaultRoute
    }
}

export default useRouteHelper