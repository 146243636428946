import React from 'react'
import useAdminStatisticsDetail from './helper'
import DashboardLayout from 'Components/DashboardLayout'
import AdminAmountCardShimmer from 'Utility/Shimmers/AdminAmountCardShimmer'
import AmountCard from 'Pages/Admin/Analytics/Elements/AmountCard'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import AdminStatisticsTable from 'Utility/Tables/AdminStatisticsTable'
import Svgs from 'Assets/svgs'
import Filters from './Filters'
import HeadingShimmerWithBackIcon from 'Utility/Shimmers/HeadingShimmerWithBackIcon'
import Back from 'Components/Elements/Back'

const AdminStatisticsDetail = () => {
    const { loader, stats, dropdownOptions, type, currentPage, setCurrentPage, navigate, addFilter, setAddFilter, filterData, setFilterData,
        handleChangeFilter, onClearFilter, onApplyFilter
    } = useAdminStatisticsDetail()

    return (
        <DashboardLayout active={'Dashboard'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                {!type ?
                    <HeadingShimmerWithBackIcon /> :
                    <div className='flex gap-2 justify-between items-center pb-4 px-2'>
                        <Back
                            title={`${type == "concierge" ? 'Concierge' : 'Business'} Statistics`}
                            onClick={() => { navigate(-1) }} />
                        <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 /></div>
                    </div>
                }
                {loader ?
                    <div className='grid lg:grid-cols-4 grid-cols-2 gap-4'>
                        {[0, 1, 2, 3]?.map((itm) => {
                            return (
                                <AdminAmountCardShimmer />
                            )
                        })}
                    </div>
                    :
                    <div className='grid lg:grid-cols-4 grid-cols-2 gap-4'>
                        <AmountCard
                            amount={stats?.total_bookings}
                            title={'Total Bookings'}
                        />
                        <AmountCard
                            amount={stats?.total_commission_generated}
                            title={'Total Commission Generated'}
                            showCurrency
                        />
                        <AmountCard
                            amount={type == "concierge" ? stats?.total_paid_to_conceirges : stats?.total_paid_to_admin}
                            title={type == "concierge" ? 'Total paid to concierges' : 'Total paid to admin'}
                            showCurrency
                        />
                        <AmountCard
                            amount={stats?.total_concierges_commission}
                            title={'Total Concierges Commission'}
                            showCurrency
                        />
                    </div>
                }
                <div className="py-6">
                    <div className="lg:col-span-8 space-y-5">
                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg border border-lightGrey">
                                {loader
                                    ? <BookingTableShimer columns={4} />
                                    :
                                    <AdminStatisticsTable data={stats?.data} type={type} />
                                }
                                {stats?.data?.length > 0
                                    && <Pagination
                                        currentPage={currentPage}
                                        pageSize={stats?.pagination_data?.meta?.per_page}
                                        totalCount={stats?.pagination_data?.meta?.total_pages}
                                        onPageChange={(page) => setCurrentPage(page)}
                                        totalDataCount={stats?.pagination_data?.meta?.total}
                                        currentPageCount={stats?.data?.length}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Filters
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                activeTab={type}
                filterData={filterData}
                setFilterData={setFilterData}
                dropdownOptions={dropdownOptions}
                handleChangeFilter={handleChangeFilter}
            />
        </DashboardLayout>
    )
}

export default AdminStatisticsDetail